import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import organizationsMappingsListSlice from './slice';
import api from '../../api/api';

const { actions } = organizationsMappingsListSlice;

function* getDataSaga({ payload = {} }) {
  try {
    const organizationsListResponse = yield call(
      api.mappings.getList,
      payload,
    );

    yield put(actions.getDataSuccess({
      list: organizationsListResponse.data.list,
      pages: organizationsListResponse.data.pages,
    }));
  } catch (e) {
    yield put(actions.getDataFailure());
  }
}

function* createOrganizationSaga({ payload }) {
  try {
    const { data } = payload;
    yield call(api.mappings.createOrganization, data);
    yield put(actions.createOrganizationSuccess());
    yield put(actions.getDataRequest());
  } catch (e) {
    yield put(actions.createOrganizationFailure());
  }
}

export default function* organizationsListSaga() {
  yield takeLatest(
    actions.getDataRequest,
    getDataSaga,
  );
  yield takeLatest(
    actions.createOrganizationRequest,
    createOrganizationSaga,
  );
}
