import React from "react";
import "./Layout.scss";
import { Container } from "semantic-ui-react";
import { Link } from "react-router-dom";

const { PUBLIC_URL } = process.env;

function Layout(props) {
  const { children, user, logout } = props;

  return (
    <div className="layout">
      <div className="main-header">
        <div className="header-container">
          <div className="header-left-group">
            <img
              alt="logo"
              className="logo"
              src={`${PUBLIC_URL}/images/fxc_logo.svg`}
            />
          </div>
          {user && (
            <div className="header-right-group">
              {user.isAdmin ? (
                <span className="header-item">
                  <i className="users icon" />
                  <Link to="/users">Users</Link>
                </span>
              ) : null}
              <span className="header-item">
                <i className="tasks icon" />
                <Link to="/audit">Audit</Link>
              </span>
              <span className="header-item">
                <i className="linkify icon" />
                <Link to="/mappings">Mappings</Link>
              </span>
              <span className="header-item">
                <i className="building outline icon" />
                <Link to="/organizations">Organizations</Link>
              </span>
              <div className="header-item-separator" />
              <span className="header-item">
                <i className="user icon" />
                {user.name || user.email}
              </span>
              <span className="header-item">
                <i className="sign-out icon" />
                <span className="action-link" onClick={logout} data-qa="logout">
                  Logout
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="content">
        <Container>{children}</Container>
      </div>
    </div>
  );
}

export default Layout;
