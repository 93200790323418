import axios from 'axios';

const lookups = {
  getLookupTypes: async (data) => (
    axios.get('/lookups/types', {params: data})
  ),
  createLookupEntry: async (data) => (
    axios.post('/lookups/data', data)
  ),
};

export default lookups;
