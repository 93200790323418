import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Grid, Header, Pagination,} from 'semantic-ui-react';
import SortableTable from '../components/SortableTable';
import Loader from '../components/Loader';
import {ATTRIBUTE_SUBTYPE_LABELS, ATTRIBUTE_TYPE_LABELS,} from '../constants/constants';
import organizationsMappingsListSlice from '../store/organizationsMappingsList/slice';
import './OrganizationsMappingsList.scss';
import QueryComposer from '../components/QueryComposer';
import SearchInput from '../components/SearchInput';
import DropdownFilter from '../components/DropdownFilter';
import CreateOrganizationModal from './CreateOrganizationModal';

const COLUMNS = {
    ORGANIZATION_NAME: 'ORGANIZATION_NAME',
    ORGANIZATION_ID: 'ORGANIZATION_ID',
    COMPANY_TYPE: 'COMPANY_TYPE',
    MARKET: 'MARKET',
    UNMAPPED_COUNT: 'UNMAPPED_COUNT',
    UNMAPPED_TYPES: 'UNMAPPED_TYPES',
    UNMAPPED_EARLIEST: 'UNMAPPED_EARLIEST',
    UNMAPPED_LATEST: 'UNMAPPED_LATEST',
    UNMAPPED_RECORDS: 'UNMAPPED_RECORDS',
};

class OrganizationsMappingsList extends React.Component {
    static columnsConfig = {
        columns: [
            COLUMNS.ORGANIZATION_ID,
            COLUMNS.ORGANIZATION_NAME,
            COLUMNS.UNMAPPED_TYPES,
            COLUMNS.UNMAPPED_COUNT,
            COLUMNS.UNMAPPED_EARLIEST,
            COLUMNS.UNMAPPED_LATEST,
            COLUMNS.UNMAPPED_RECORDS,
        ],
        keyAttribute: 'organization_id',
        defaultText: '-',
        config: {
            [COLUMNS.ORGANIZATION_ID]: {
                label: 'Organization Id',
                sortable: true,
                dataAttribute: 'organization_id',
            },
            [COLUMNS.ORGANIZATION_NAME]: {
                label: 'Organization Name',
                sortable: true,
                dataAttribute: 'organization_name',
                customRender: true,
            },
            [COLUMNS.UNMAPPED_TYPES]: {
                label: 'Mapping Field',
                dataAttribute: 'unmapped_types',
                customRender: true,
            },
            [COLUMNS.UNMAPPED_COUNT]: {
                label: 'Fields Not Mapped',
                sortable: true,
                dataAttribute: 'unmapped_count',
            },
            [COLUMNS.UNMAPPED_EARLIEST]: {
                label: 'Earliest Not Mapped Record',
                sortable: true,
                dataAttribute: 'unmapped_earliest',
                customRender: true,
            },
            [COLUMNS.UNMAPPED_LATEST]: {
                label: 'Latest Not Mapped Record',
                sortable: true,
                dataAttribute: 'unmapped_latest',
                customRender: true,
            },
            [COLUMNS.UNMAPPED_RECORDS]: {
                label: 'Records Not Mapped',
                sortable: true,
                dataAttribute: 'unmapped_records',
            },
        },
    };

    static filterConfig = {
        options: [
            {
                text: 'Not Selected',
                value: false,
            },
            ...Object
                .entries(ATTRIBUTE_TYPE_LABELS)
                .map(([key, label]) => ({
                    text: label,
                    value: key,
                })),
        ],
        defaultValue: false,
        attribute: OrganizationsMappingsList
            .columnsConfig
            .config[COLUMNS.UNMAPPED_TYPES]
            .dataAttribute,
    };

    componentWillUnmount() {
        const {resetState} = this.props;
        resetState();
    }

    renderOrganizationName = (column, item) => {
        const {config} = OrganizationsMappingsList.columnsConfig;
        const idAttribute = config[COLUMNS.ORGANIZATION_ID].dataAttribute;
        const currentAttribute = config[column].dataAttribute;

        return (
            <Link to={`/mappings/${item[idAttribute]}`}>
                {item[currentAttribute] || 'No Name'}
            </Link>
        );
    };

    renderUnmappedTypes = (column, item) => {
        const {config, defaultText} = OrganizationsMappingsList.columnsConfig;
        const currentAttribute = config[column].dataAttribute;
        return item[currentAttribute]
            .map((attributeType) => (
                ATTRIBUTE_TYPE_LABELS[attributeType] ||
                ATTRIBUTE_SUBTYPE_LABELS[attributeType] ||
                attributeType
            ))
            .join(', ') || defaultText;
    };

    renderItem = (column, item) => {
        switch (column) {
            case COLUMNS.ORGANIZATION_NAME:
                return this.renderOrganizationName(column, item);
            case COLUMNS.UNMAPPED_TYPES:
                return this.renderUnmappedTypes(column, item);
            case COLUMNS.UNMAPPED_EARLIEST:
            case COLUMNS.UNMAPPED_LATEST:
                return this.renderDateColumn(column, item);
            default:
                return null;
        }
    };

    renderDateColumn = (column, item) => {
        const {dataAttribute} = OrganizationsMappingsList.columnsConfig.config[column];
        const value = item[dataAttribute];
        if (!value) return '-';
        const date = new Date(item[dataAttribute]);
        return [
            date.getFullYear(),
            String(date.getMonth() + 1).padStart(2, '0'),
            String(date.getDate()).padStart(2, '0'),
        ].join('-');
    };

    setColumnClassName = (column) => (
        column === COLUMNS.ORGANIZATION_NAME
            ? 'overflow-elipsis'
            : ''
    );

    render() {
        const {
            list,
            pages,
            isLoading,
            getDataRequest,
            setCreateOrganization,
            createOrganizationRequest,
            isCreateOrganization,
        } = this.props;

        const {config} = OrganizationsMappingsList.columnsConfig;

        return (
            <div className="organizations-mappings-list">
                <QueryComposer onChange={getDataRequest}>
                    {(query, setQuery) => (
                        <>
                            {isCreateOrganization && (
                                <CreateOrganizationModal
                                    onSave={(org) => createOrganizationRequest({data: org})}
                                    onCancel={() => setCreateOrganization(false)}
                                />
                            )}
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column
                                        className="flex align-items-center"
                                        width={4}
                                    >
                                        <Header size="large">
                                            Pricing Mapping
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column
                                        className="flex justify-content-end align-items-center spaced-items"
                                        width={12}
                                    >
                                        <SearchInput
                                            onChange={(search) => setQuery({
                                                search: search.length ? search : null,
                                                page: null,
                                            })}
                                            isSyncToDefault
                                            defaultValue={query.search}
                                            placeholder="Id or Name..."
                                            dataqa="search-filter"
                                        />
                                        <DropdownFilter
                                            data-qa="table-filter"
                                            options={OrganizationsMappingsList.filterConfig.options}
                                            value={query.type || OrganizationsMappingsList.filterConfig.defaultValue}
                                            onChange={(e, data) => setQuery({
                                                type: data.value !== OrganizationsMappingsList.filterConfig.defaultValue
                                                    ? data.value
                                                    : null,
                                                page: null,
                                            })}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {isLoading ? (
                                <Loader isLoading={isLoading}/>
                            ) : (
                                <>
                                    <SortableTable
                                        items={list}
                                        columnsConfig={OrganizationsMappingsList.columnsConfig}
                                        renderItem={this.renderItem}
                                        setColumnClassName={this.setColumnClassName}
                                        isExternalSort
                                        onSortChange={({sortColumn, sortOrder}) => setQuery({
                                            sortBy: sortColumn && config[sortColumn].dataAttribute,
                                            sortOrder,
                                        })}
                                        sortColumn={
                                            query.sortBy &&
                                            Object
                                                .keys(config)
                                                .find((key) => config[key].dataAttribute === query.sortBy)
                                        }
                                        sortOrder={query.sortOrder}
                                    />
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column textAlign="center">
                                                <Pagination
                                                    activePage={query.page || 1}
                                                    totalPages={pages || 1}
                                                    onPageChange={(_, data) => setQuery({
                                                        page: data.activePage,
                                                    })}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </QueryComposer>
            </div>
        );
    }
}

const {
    getDataRequest,
    resetState,
    setCreateOrganization,
    createOrganizationRequest,
} = organizationsMappingsListSlice.actions;

export default connect(
    ({
         [organizationsMappingsListSlice.name]: {
             list,
             pages,
             isLoading,
             isCreateOrganization,
         },
     }) => ({
        list,
        pages,
        isLoading,
        isCreateOrganization,
    }),
    {
        getDataRequest,
        resetState,
        setCreateOrganization,
        createOrganizationRequest,
    },
)(OrganizationsMappingsList);
