export const KNOWN_ATTRIBUTE_TYPES = {
  PAY_IN_METHOD: 'payment_method',
  PAY_OUT_METHOD: 'payout_method',
  COUNTRY_FROM: 'country_from',
  COUNTRY_TO: 'country_to',
  CURRENCY: 'currency',
  STATE_FROM: 'state_from',
  STATE_TO: 'state_to',
  ZIP_CODE_FROM: 'zip_code_from',
  ZIP_CODE_TO: 'zip_code_to',
  TRANSFER_SPEED: 'transfer_speed',
};

export const CURRENCY_SUBTYPES = {
  AMOUNT_FROM_CURRENCY: 'amount_from_currency',
  AMOUNT_TO_CURRENCY: 'amount_to_currency',
  BUY_CURRENCY: 'buy_currency',
  SELL_CURRENCY: 'sell_currency',
  FEE_CURRENCY: 'fee_currency',
  FEE_MIN_CURRENCY: 'fee_min_currency',
  FEE_MAX_CURRENCY: 'fee_max_currency',
};

export const ATTRIBUTE_TYPE_LABELS = {
  [KNOWN_ATTRIBUTE_TYPES.PAY_IN_METHOD]: 'Pay in method',
  [KNOWN_ATTRIBUTE_TYPES.PAY_OUT_METHOD]: 'Pay out method',
  [KNOWN_ATTRIBUTE_TYPES.COUNTRY_FROM]: 'Country from',
  [KNOWN_ATTRIBUTE_TYPES.COUNTRY_TO]: 'Country to',
  [KNOWN_ATTRIBUTE_TYPES.CURRENCY]: 'Currency',
  [KNOWN_ATTRIBUTE_TYPES.STATE_FROM]: 'State from',
  [KNOWN_ATTRIBUTE_TYPES.STATE_TO]: 'State to',
  [KNOWN_ATTRIBUTE_TYPES.ZIP_CODE_FROM]: 'Zip code from',
  [KNOWN_ATTRIBUTE_TYPES.ZIP_CODE_TO]: 'Zip code to',
  [KNOWN_ATTRIBUTE_TYPES.TRANSFER_SPEED]: 'Transfer speed',
};

export const ATTRIBUTE_SUBTYPE_LABELS = {
  [CURRENCY_SUBTYPES.AMOUNT_FROM_CURRENCY]: 'Amount from currency',
  [CURRENCY_SUBTYPES.AMOUNT_TO_CURRENCY]: 'Amount to currency',
  [CURRENCY_SUBTYPES.BUY_CURRENCY]: 'Buy currency',
  [CURRENCY_SUBTYPES.SELL_CURRENCY]: 'Sell currency',
  [CURRENCY_SUBTYPES.FEE_CURRENCY]: 'Fee currency',
  [CURRENCY_SUBTYPES.FEE_MIN_CURRENCY]: 'Fee min currency',
  [CURRENCY_SUBTYPES.FEE_MAX_CURRENCY]: 'Fee max currency',
};

export const ATTRIBUTE_TYPE_MAPPING_DATA = {
  [KNOWN_ATTRIBUTE_TYPES.PAY_IN_METHOD]: 'paymentMethods',
  [KNOWN_ATTRIBUTE_TYPES.PAY_OUT_METHOD]: 'payoutMethods',
  [KNOWN_ATTRIBUTE_TYPES.COUNTRY_FROM]: 'countries',
  [KNOWN_ATTRIBUTE_TYPES.COUNTRY_TO]: 'countries',
  [KNOWN_ATTRIBUTE_TYPES.CURRENCY]: 'currencies',
  [KNOWN_ATTRIBUTE_TYPES.STATE_FROM]: 'states',
  [KNOWN_ATTRIBUTE_TYPES.STATE_TO]: 'states',
  [KNOWN_ATTRIBUTE_TYPES.ZIP_CODE_FROM]: 'zipCodes',
  [KNOWN_ATTRIBUTE_TYPES.ZIP_CODE_TO]: 'zipCodes',
  [KNOWN_ATTRIBUTE_TYPES.TRANSFER_SPEED]: 'transferSpeeds',
};

export default {
  KNOWN_ATTRIBUTE_TYPES,
  ATTRIBUTE_TYPE_LABELS,
  ATTRIBUTE_TYPE_MAPPING_DATA,
};
