import { createSlice } from '@reduxjs/toolkit';

const newOrganizationState = {
  is_pricing_displayed: true,
  google_trends_topic: null,
}

const initialState = {
  isLoading: false,
  initialData: { ...newOrganizationState },
  currentData: { ...newOrganizationState },
  isDuplicate: false,
};

const organizationEditSlice = createSlice({
  name: 'organizationEdit',
  initialState,
  reducers: {
    setDataValue: (state, { payload }) => ({
      ...state,
      currentData: {
        ...state.currentData,
        ...payload,
      },
      isDuplicate: payload.name ? false : state.isDuplicate,
    }),
    getDataRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getDataSuccess: (state, { payload }) => ({
      ...state,
      initialData: { ...payload.data },
      currentData: { ...payload.data },
      isLoading: false,
    }),
    getDataFailure: (state) => ({
      ...state,
      isLoading: false,
    }),
    saveDataRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    saveDataSuccess: (state) => ({
      ...state,
      isLoading: false,
    }),
    saveDataFailure: (state, { payload = undefined }) => ({
      ...state,
      isLoading: false,
      isDuplicate: payload === 'Organization with this name already exists',
    }),
    resetState: () => initialState,
  },
});

export default organizationEditSlice;
