import {
  takeLatest,
  call,
  put,
  all,
  select,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import organizationMappingSlice from './slice';
import api from '../../api/api';

const { actions } = organizationMappingSlice;

function* getDataSaga({ payload }) {
  try {
    const [
      organizationDataResponse,
      organizationInfoResponse,
    ] = yield all([
      call(api.mappings.getDetail, {
        organizationId: payload.organizationId,
      }),
      call(api.organizations.getById, {
        id: payload.organizationId,
      })
    ]);
    yield put(actions.getDataSuccess({
      organizationData: {
        mappedData: organizationDataResponse
          .data
          .mappedData
          .map((item) => ({ ...item, meta: { key: uuidv4() } })),
        unmappedData: organizationDataResponse
          .data
          .unmappedData
          .map((item) => ({ ...item, meta: { key: uuidv4() } })),
      },
      paymentMethods: organizationDataResponse.data.paymentMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      payoutMethods: organizationDataResponse.data.payoutMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      splitPayoutMethods: organizationDataResponse.data.splitPayoutMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      splitPaymentMethods: organizationDataResponse.data.splitPaymentMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      organizationInfo: organizationInfoResponse.data.data,
    }));
  } catch (e) {
    yield put(actions.getDataFailure());
  }
}
function* deleteMethodsSaga({ payload }) {
  try {
    yield api.mappings.deleteSplitMethod({
      organizationId: payload.item.organizationId,
      data: {
        parentId: payload.item.parentId,
        paymentMethod: payload.item.paymentMethod,
        id: payload.item.id,
        organizationId: payload.item.organizationId,
        splitMappingId: payload.item.splitMappingId
      }
    });
    const [
      organizationDataResponse,
      organizationInfoResponse,
    ] = yield all([
      call(api.mappings.getDetail, {
        organizationId: payload.item.organizationId,
      }),
      call(api.organizations.getById, {
        id: payload.item.organizationId,
      })
    ]);
    yield put(actions.getDataSuccess({
      organizationData: {
        mappedData: organizationDataResponse
          .data
          .mappedData
          .map((item) => ({ ...item, meta: { key: uuidv4() } })),
        unmappedData: organizationDataResponse
          .data
          .unmappedData
          .map((item) => ({ ...item, meta: { key: uuidv4() } })),
      },
      paymentMethods: organizationDataResponse.data.paymentMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      payoutMethods: organizationDataResponse.data.payoutMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      splitPayoutMethods: organizationDataResponse.data.splitPayoutMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      splitPaymentMethods: organizationDataResponse.data.splitPaymentMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      organizationInfo: organizationInfoResponse.data.data,
    }));
    
  } catch (e) {
    yield put(actions.deleteDataFailure());
  }
}
function* saveSplitSaga({ payload }) {
  try {
    yield api.mappings.saveSplit({
      organizationId: payload[0].organization_id,
      data: payload
    });
    const [
      organizationDataResponse,
      organizationInfoResponse,
    ] = yield all([
      call(api.mappings.getDetail, {
        organizationId: payload[0].organization_id,
      }),
      call(api.organizations.getById, {
        id: payload[0].organization_id,
      })
    ]);
    yield put(actions.getDataSuccess({
      organizationData: {
        mappedData: organizationDataResponse
          .data
          .mappedData
          .map((item) => ({ ...item, meta: { key: uuidv4() } })),
        unmappedData: organizationDataResponse
          .data
          .unmappedData
          .map((item) => ({ ...item, meta: { key: uuidv4() } })),
      },
      paymentMethods: organizationDataResponse.data.paymentMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      payoutMethods: organizationDataResponse.data.payoutMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      splitPayoutMethods: organizationDataResponse.data.splitPayoutMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      splitPaymentMethods: organizationDataResponse.data.splitPaymentMethods.map((item) => ({ ...item, meta: { key: uuidv4() } })),
      organizationInfo: organizationInfoResponse.data.data,
    }));
  } catch (e) {
    yield put(actions.deleteDataFailure());
  }
}
function* saveDataSaga({ payload }) {
  try {
    const { organizationData } = yield select(
      (state) => state[organizationMappingSlice.name],
    );

    const changedData = organizationData.reduce((result, item) => {
      const { meta, ...changedItem } = item;
      return meta && meta.changed
        ? [...result, changedItem]
        : result;
    }, []);

    yield api.mappings.save({
      organizationId: payload.organizationId,
      data: changedData,
    });

    yield put(actions.saveDataSuccess());
    yield put(actions.getDataRequest({
      organizationId: payload.organizationId,
    }));
  } catch (e) {
    yield put(actions.saveDataFailure());
  }
}

function* saveNewMappingSaga() {
  try {
    const { newMapping, organizationInfo } = yield select(
      (state) => state[organizationMappingSlice.name],
    );

    yield api.lookups.createLookupEntry(newMapping);
    yield put(actions.saveNewMappingSuccess());
    yield put(actions.getDataRequest({
      organizationId: organizationInfo.id,
    }));
  } catch (e) {
    yield put(actions.saveNewMappingFailure());
  }
}

export default function* organizationMappingSaga() {
  yield takeLatest(
    actions.getDataRequest,
    getDataSaga,
  );

  yield takeLatest(
    actions.saveDataRequest,
    saveDataSaga,
  );
  yield takeLatest(
    actions.deleteMethodsRequest,
    deleteMethodsSaga,
  );
  yield takeLatest(
    actions.saveSplit,
    saveSplitSaga,
  );
  yield takeLatest(
    actions.saveNewMappingRequest,
    saveNewMappingSaga,
  );
}
