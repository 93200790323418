import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isModalLoading: false,
  isCreateOrganization: false,
  list: [],
  pages: 1,
};

const organizationMappingSlice = createSlice({
  name: 'organizationsMappingsList',
  initialState,
  reducers: {
    getDataRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getDataSuccess: (state, { payload }) => ({
      ...state,
      list: [
        ...payload.list,
      ],
      pages: payload.pages,
      isLoading: false,
    }),
    getDataFailure: (state) => ({
      ...state,
      isLoading: false,
    }),
    createOrganizationRequest: (state) => ({
      ...state,
      isModalLoading: true,
    }),
    createOrganizationSuccess: (state) => ({
      ...state,
      isModalLoading: false,
      isCreateOrganization: false,
    }),
    createOrganizationFailure: (state) => ({
      ...state,
      isModalLoading: false,
    }),
    setCreateOrganization: (state, { payload }) => ({
      ...state,
      isCreateOrganization: !!payload,
    }),
    resetState: () => initialState,
  },
});

export default organizationMappingSlice;
