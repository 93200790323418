import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  list: [],
  pages: 1,
};

const organizationsListSlice = createSlice({
  name: 'organizationsList',
  initialState,
  reducers: {
    getOrganizationsRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getOrganizationsSuccess: (state, { payload }) => ({
      ...state,
      list: [
        ...payload.list,
      ],
      pages: payload.pages,
      isLoading: false,
    }),
    getOrganizationsFailure: (state) => ({
      ...state,
      isLoading: false,
    }),
    resetState: () => initialState,
  },
});

export default organizationsListSlice;
