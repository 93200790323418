import axios from "axios";
import get from "lodash/get";
import mappings from "./mappings";
import lookups from "./lookups";
import auth from "./auth";
import users from "./users";
import audit from "./audit";
import organizations from "./organizations";
import googleTrendsTopics from "./googleTrendsTopics";

import storage from "../utils/storage";
import errorHandlerSlice from "../store/errorHandler/slice";
import store from "../store";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

let deauthorizeCallback;
function onDeauthorize(cb) {
  deauthorizeCallback = cb;
}

axios.interceptors.request.use((config) => {
  const token = storage.getToken();
  // eslint-disable-next-line no-param-reassign
  if (token) config.headers.Authorization = token;
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const code = error.response.status;
    if (code === 401) {
      storage.removeToken();
      if (deauthorizeCallback) deauthorizeCallback();
    }
    if (code >= 400) {
      const message = get(error.response, "data.message");
      store.dispatch(errorHandlerSlice.actions.failure({ message, code }));
    }
    return Promise.reject(error);
  }
);

const api = {
  organizations,
  mappings,
  lookups,
  auth,
  users,
  audit,
  googleTrendsTopics
};

export { onDeauthorize };
export default api;
