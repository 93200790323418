import React from 'react';
import { connect } from 'react-redux';
import errorHandlerSlice from '../store/errorHandler/slice';
import Snackbar from '../components/Snackbar';

const ErrorHandler = ({
  errorCode, errorMessage, errorId, children,
}) => {
  switch (errorCode) {
    default:
      return (
        <>
          {errorMessage && <Snackbar variant="error" key={errorId}>{errorMessage}</Snackbar>}
          {children}
        </>
      );
  }
};

export default connect(
  ({
    [errorHandlerSlice.name]: {
      id,
      errorCode,
      errorMessage,
    },
  }) => ({
    id,
    errorCode,
    errorMessage,
  }),
)(ErrorHandler);
