import React from "react";
import { Provider, connect } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import store from "../store";
import Layout from "../Layout/Layout";
import "./App.scss";
import OrganizationMapping from "../OrganizationMapping/OrganizationMapping";
import OrganizationsMappingsList from "../OrganizationsMappingsList/OrganizationsMappingsList";
import Login from "../Login/Login";
import UsersList from "../UsersList/UsersList";
import OrganizationsList from "../OrganizationsList/OrganizationsList";
import authSlice from "../store/auth/slice";
import { onDeauthorize } from "../api/api";
import MappingAudit from "../MappingAudit/MappingAudit";
import OrganizationEdit from "../OrganizationEdit/OrganizationEdit";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

const { actions } = authSlice;

onDeauthorize(() => {
  store.dispatch(actions.logout());
});

const Routing = connect(
  ({ [authSlice.name]: { user } }) => ({
    user,
  }),
  {
    logout: actions.logout,
  }
)((props) => (
  <Layout user={props.user} logout={props.logout}>
    <ErrorHandler>
      {props.user ? (
        <Routes>
          <Route
            path="/mappings/:organizationId"
            element={<OrganizationMapping/>}
            exact
          />
          <Route path="/mappings" element={<OrganizationsMappingsList/>} />
          <Route path="/organizations/new" element={<OrganizationEdit/>} />
          <Route path="/organizations/:id" element={<OrganizationEdit/>} />
          <Route path="/organizations" element={<OrganizationsList/>} />
          {props.user.isAdmin &&
            <Route path="/users" element={<UsersList/>} />
          }
          <Route path="/audit" element={<MappingAudit/>} />
          <Route path="/login" element={<Navigate to="/mappings" />} />
          <Route path="/" element={<Navigate to="/mappings" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<Login/>} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </ErrorHandler>
  </Layout>
));

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routing />
      </Router>
    </Provider>
  );
}

export default App;
