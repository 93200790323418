import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isModalLoading: false,
  lookupData: {},
  organizationData: [],
  paymentMethods: [],
  payoutMethods: [],
  splitPayoutMethods: [],
  splitPaymentMethods: [],
  organizationInfo: {},
  newMapping: {},
};

const organizationMappingSlice = createSlice({
  name: 'organizationMapping',
  initialState,
  reducers: {
    setDataValue: (state, { payload }) => ({
      ...state,
      organizationData: state.organizationData.map((item) => (
        payload.item.meta.key === item.meta.key
          ? {
            ...item,
            [payload.attribute]: payload.value,
            type: payload.item.type,
            meta: {
              ...item.meta,
              changed: true,
            },
          }
          : item
      )),
    }),
    getDataRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getDataSuccess: (state, { payload }) => ({
      ...state,
      organizationData: [
        ...payload.organizationData.unmappedData,
        ...payload.organizationData.mappedData,
      ],
      lookupData: { ...payload.lookupData },
      organizationInfo: { ...payload.organizationInfo },
      paymentMethods: [ ...payload.paymentMethods ],
      payoutMethods: [ ...payload.payoutMethods ],
      splitPayoutMethods: [ ...payload.splitPayoutMethods ],
      splitPaymentMethods: [ ...payload.splitPaymentMethods ],
      isLoading: false,
    }),
    getDataFailure: (state) => ({
      ...state,
      isLoading: false,
    }),
    saveDataRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    deleteMethodsRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    saveSplit: (state) => ({
      ...state,
      isLoading: true,
    }),
    saveDataSuccess: (state) => ({
      ...state,
      isLoading: false,
      organizationData: state.organizationData.map((item) => ({
        ...item,
        meta: {
          ...item.meta,
          changed: undefined,
        },
      })),
    }),
    saveSplitDataSuccess: (state) => ({
      ...state,
      isLoading: false,
    }),
    saveDataFailure: (state) => ({
      ...state,
      isLoading: false,
    }),
    deleteDataFailure: (state) => ({
      ...state,
      isLoading: false,
    }),
    setNewMappingData: (state, { payload }) => ({
      ...state,
      newMapping: {
        ...state.newMapping,
        [payload.attribute]: payload.value,
      },
    }),
    resetNewMappingData: (state) => ({
      ...state,
      newMapping: {},
    }),
    saveNewMappingRequest: (state) => ({
      ...state,
      isModalLoading: true,
    }),
    saveNewMappingSuccess: (state) => ({
      ...state,
      isModalLoading: false,
      newMapping: {},
    }),
    saveNewMappingFailure: (state) => ({
      ...state,
      isModalLoading: false,
    }),
    resetState: () => initialState,
  },
});

export default organizationMappingSlice;
