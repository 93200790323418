import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import usersListSlice from './slice';
import api from '../../api/api';

const { actions } = usersListSlice;

function* getUsersSaga({ payload = {} }) {
  try {
    const usersListResponse = yield call(
      api.users.getUsers,
      payload,
    );

    yield put(actions.getUsersSuccess({
      list: usersListResponse.data.list,
      pages: usersListResponse.data.pages,
    }));
  } catch (e) {
    yield put(actions.getUsersFailure());
  }
}

function* saveUserSaga({ payload }) {
  try {
    const { data, id, fetchQuery } = payload;

    yield id
      ? call(api.users.updateUser, { data, id })
      : call(api.users.createUser, { data });

    yield put(actions.saveUserSuccess({ fetchQuery }));
  } catch (e) {
    yield put(actions.saveUserFailure());
  }
}

function* deleteUserSaga({ payload }) {
  try {
    const { id, fetchQuery } = payload;
    yield call(api.users.deleteUser, { id });
    yield put(actions.deleteUserSuccess({ fetchQuery }));
  } catch (e) {
    yield put(actions.deleteUserFailure());
  }
}

function* reloadUsersPage({ payload }) {
  const { fetchQuery } = payload;
  yield put(actions.getUsersRequest(fetchQuery));
}

export default function* usersListSaga() {
  yield takeLatest(
    actions.getUsersRequest,
    getUsersSaga,
  );
  yield takeLatest(
    actions.saveUserRequest,
    saveUserSaga,
  );
  yield takeLatest(
    actions.deleteUserRequest,
    deleteUserSaga,
  );
  yield takeLatest(
    [actions.saveUserSuccess, actions.deleteUserSuccess],
    reloadUsersPage,
  );
}
