import axios from "axios";
import qs from "qs";

const organizations = {
  getList: async (query = {}) =>
    axios.get(`/organizations?${qs.stringify(query)}`),
  getById: async ({ id }) => axios.get(`/organizations/${id}`),
  create: async ({ data }) => axios.post("/organizations", data),
  update: async ({ data, id }) => axios.put(`/organizations/${id}`, data),
  getAutocomplite: async (query = {}) =>
    axios.get(`/organizations/autocomplete?${qs.stringify(query)}`),
};

export default organizations;
