import { useLocation, useNavigate, useParams } from "react-router-dom";

// This function is necessary for react-router-dom v6 as we still 
// use class components but v6 provides only the hook approach 
// available only for function components. 
// The function imitates withRouter function of react-router-dom v5 
// and provides the required props. 
// We can remove it after we move on to function components.
export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        navigate={navigate}
        location={location}
        params={params}
      />
    );
  }

  return ComponentWithRouterProp;
}
