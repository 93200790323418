import React from 'react';
import {
  Modal as UIModal,
} from 'semantic-ui-react';
import './Modal.scss';

function Modal(props) {
  const {
    title,
    children,
    actions,
    contentRef,
    ...forwardedProps
  } = props;

  return (
    <div className="app-modal">
      <UIModal
        dimmer="inverted"
        size="small"
        {...forwardedProps}
      >
        <UIModal.Header>
          {title}
        </UIModal.Header>
        <UIModal.Content>
          <div ref={contentRef}>
            {children}
          </div>
        </UIModal.Content>
        <UIModal.Actions>
          {actions}
        </UIModal.Actions>
      </UIModal>
    </div>
  );
}

export default Modal;
