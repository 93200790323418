import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import Select from 'react-select';
import Modal from '../components/Modal';
import './CreateOrganizationModal.scss';

const DATA_ATTRIBUTES = {
  ORGANIZATION_NAME: 'organization_name',
  ORGANIZATION_WEBSITE: 'organization_website',
  COUNTRY: 'country',
  DUNS_NUMBER: 'duns_number',
  PARENT_ORGANIZATION: 'parent_organization',
  SUBSIDIARY: 'subsidiary',
};

const DATA_LABELS = {
  [DATA_ATTRIBUTES.ORGANIZATION_NAME]: 'Organization Name',
  [DATA_ATTRIBUTES.ORGANIZATION_WEBSITE]: 'Organization Website',
  [DATA_ATTRIBUTES.COUNTRY]: 'Country',
  [DATA_ATTRIBUTES.DUNS_NUMBER]: 'DUNS Number',
  [DATA_ATTRIBUTES.PARENT_ORGANIZATION]: 'Parent Organization',
  [DATA_ATTRIBUTES.SUBSIDIARY]: 'Subsidiary',
};

// TODO: replace with real data
const countryOptions = [
  { label: 'USA', value: 1 },
  { label: 'United Kingdom', value: 2 },
  { label: 'Ukraine', value: 3 },
];

// TODO: replace with real data
const organizationOptions = [
  { label: 'Org 1', value: 1 },
  { label: 'Org 2', value: 2 },
  { label: 'Org 3', value: 3 },
];

class CreateOrganizationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organization: {},
    };
  }

  static validate(data) {
    const attrs = [
      DATA_ATTRIBUTES.ORGANIZATION_NAME,
      DATA_ATTRIBUTES.ORGANIZATION_WEBSITE,
      DATA_ATTRIBUTES.COUNTRY,
    ];

    return attrs.every((attr) => (
      data[attr] != null &&
      data[attr] !== ''
    ));
  }

  onValueChange = (attr, value) => {
    this.setState((state) => ({
      organization: {
        ...state.organization,
        [attr]: value,
      },
    }));
  }

  render() {
    const {
      onCancel,
      onSave,
    } = this.props;

    const {
      organization,
    } = this.state;

    return (
      <Modal
        title="Create Organization"
        open
        actions={(
          <>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              color="blue"
              type="submit"
              form="createOrganization"
              disabled={!CreateOrganizationModal.validate(organization)}
            >
              Save Organization
            </Button>
          </>
        )}
      >
        <Form onSubmit={() => onSave(organization)} id="createOrganization">
          <Form.Group widths={2}>
            <Form.Input
              required
              name={DATA_ATTRIBUTES.ORGANIZATION_NAME}
              label={DATA_LABELS[DATA_ATTRIBUTES.ORGANIZATION_NAME]}
              value={organization[DATA_ATTRIBUTES.ORGANIZATION_NAME] ?? ''}
              onChange={(e) => this.onValueChange(
                DATA_ATTRIBUTES.ORGANIZATION_NAME,
                e.target.value,
              )}
            />
            <Form.Input
              required
              name={DATA_ATTRIBUTES.ORGANIZATION_WEBSITE}
              label={DATA_LABELS[DATA_ATTRIBUTES.ORGANIZATION_WEBSITE]}
              value={organization[DATA_ATTRIBUTES.ORGANIZATION_WEBSITE] ?? ''}
              onChange={(e) => this.onValueChange(
                DATA_ATTRIBUTES.ORGANIZATION_WEBSITE,
                e.target.value,
              )}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field
              required
              placeholder="Select Country..."
              className="selectbox"
              classNamePrefix="selectbox"
              name={DATA_ATTRIBUTES.COUNTRY}
              label={DATA_LABELS[DATA_ATTRIBUTES.COUNTRY]}
              onChange={(data) => this.onValueChange(
                DATA_ATTRIBUTES.COUNTRY,
                data.value,
              )}
              value={countryOptions.find((option) => (
                option.value === organization[DATA_ATTRIBUTES.COUNTRY]
              ))}
              options={countryOptions}
              control={Select}
            />
            <Form.Input
              name={DATA_ATTRIBUTES.DUNS_NUMBER}
              label={DATA_LABELS[DATA_ATTRIBUTES.DUNS_NUMBER]}
              value={organization[DATA_ATTRIBUTES.DUNS_NUMBER] ?? ''}
              onChange={(e) => this.onValueChange(
                DATA_ATTRIBUTES.DUNS_NUMBER,
                e.target.value,
              )}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field
              placeholder="Select Organization..."
              className="selectbox"
              classNamePrefix="selectbox"
              name={DATA_ATTRIBUTES.PARENT_ORGANIZATION}
              label={DATA_LABELS[DATA_ATTRIBUTES.PARENT_ORGANIZATION]}
              onChange={(data) => this.onValueChange(
                DATA_ATTRIBUTES.PARENT_ORGANIZATION,
                data.value,
              )}
              value={countryOptions.find((option) => (
                option.value === organization[DATA_ATTRIBUTES.PARENT_ORGANIZATION]
              ))}
              options={organizationOptions}
              control={Select}
            />
            <Form.Field
              placeholder="Select Organization..."
              className="selectbox"
              classNamePrefix="selectbox"
              name={DATA_ATTRIBUTES.SUBSIDIARY}
              label={DATA_LABELS[DATA_ATTRIBUTES.SUBSIDIARY]}
              onChange={(data) => this.onValueChange(
                DATA_ATTRIBUTES.SUBSIDIARY,
                data.value,
              )}
              value={countryOptions.find((option) => (
                option.value === organization[DATA_ATTRIBUTES.SUBSIDIARY]
              ))}
              options={organizationOptions}
              control={Select}
            />
          </Form.Group>
        </Form>
      </Modal>
    );
  }
}

export default CreateOrganizationModal;
