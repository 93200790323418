import axios from 'axios';
import qs from 'qs';

const audit = {
  getAudit: async (query = {}) => (
    axios.get(`/audit?${qs.stringify(query)}`)
  ),
};

export default audit;
