import React from 'react';
import { Button, Form, Confirm } from 'semantic-ui-react';
import Modal from '../components/Modal';

const DATA_ATTRIBUTES = {
  ID: 'id',
  EMAIL: 'email',
  NAME: 'name',
  PASSWORD: 'password',
  PASSWORD_REPEAT: 'password_repeat',
  IS_ADMIN: 'is_admin',
  IS_ACTIVE: 'is_active',
};

const DATA_LABELS = {
  [DATA_ATTRIBUTES.EMAIL]: 'Email',
  [DATA_ATTRIBUTES.NAME]: 'Name',
  [DATA_ATTRIBUTES.PASSWORD]: 'Password',
  [DATA_ATTRIBUTES.PASSWORD_REPEAT]: 'Repeat Password',
  [DATA_ATTRIBUTES.IS_ADMIN]: 'Administrator',
  [DATA_ATTRIBUTES.IS_ACTIVE]: 'Active',
};

const ERROR_MESSAGES = {
  [DATA_ATTRIBUTES.PASSWORD_REPEAT]: 'Password repeat doesn\'t match password',
};

const validation = {
  [DATA_ATTRIBUTES.PASSWORD_REPEAT]: (target, state) => {
    const {
      user: {
        [DATA_ATTRIBUTES.PASSWORD]: password,
        [DATA_ATTRIBUTES.PASSWORD_REPEAT]: passwordRepeat,
      },
    } = state;

    target.setCustomValidity(
      (password && passwordRepeat && password !== passwordRepeat)
        ? ERROR_MESSAGES[DATA_ATTRIBUTES.PASSWORD_REPEAT]
        : '',
    );
  },
};

class EditUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        ...Object
          .values(DATA_ATTRIBUTES)
          .reduce((acc, dataAttr) => ({
            ...acc,
            [dataAttr]: props.user[dataAttr],
          }), {}),
      },
      isConfirm: false,
    };

    this.modalContentRef = React.createRef();
  }

  onValueChange = (attr, value, e) => {
    const { target } = e;

    this.setState((state) => ({
      user: {
        ...state.user,
        [attr]: value,
      },
    }), () => {
      const validate = validation[attr];
      if (validate) validate(target, this.state);
    });
  }

  showConfirm = (isConfirm) => {
    this.setState({ isConfirm });
  };

  render() {
    const {
      isOpen,
      onCancel,
      onDelete,
      onSave,
      isCurrentUser,
    } = this.props;
    const { user, isConfirm } = this.state;
    const isNew = !user[DATA_ATTRIBUTES.ID];

    return (
      <Modal
        title={
          isNew
            ? 'Create User'
            : `Edit User #${user[DATA_ATTRIBUTES.ID]} ${isCurrentUser ? '(Current User)' : ''}`
        }
        open={isOpen}
        contentRef={this.modalContentRef}
        actions={(
          <>
            <Button onClick={onCancel}>Cancel</Button>
            {(!isNew && !isCurrentUser) && (
              <Button color="red" onClick={() => this.showConfirm(true)}>
                Delete User
              </Button>
            )}
            <Button color="blue" type="submit" form="editUser">
              Save User
            </Button>
          </>
        )}
      >
        <Confirm
          content="Are you sure you want to delete this user?"
          confirmButton="Confirm"
          size="mini"
          dimmer="inverted"
          open={isConfirm}
          mountNode={this.modalContentRef.current}
          onCancel={() => this.showConfirm(false)}
          onConfirm={() => {
            this.showConfirm(false);
            onDelete(user);
          }}
        />
        <Form onSubmit={() => onSave(user)} id="editUser">
          <Form.Group widths={2}>
            <Form.Input
              type="email"
              required
              name={DATA_ATTRIBUTES.EMAIL}
              label={DATA_LABELS[DATA_ATTRIBUTES.EMAIL]}
              value={user[DATA_ATTRIBUTES.EMAIL] ?? ''}
              onChange={(e) => this.onValueChange(
                DATA_ATTRIBUTES.EMAIL,
                e.target.value,
                e,
              )}
            />
            <Form.Input
              name={DATA_ATTRIBUTES.NAME}
              label={DATA_LABELS[DATA_ATTRIBUTES.NAME]}
              value={user[DATA_ATTRIBUTES.NAME] ?? ''}
              onChange={(e) => this.onValueChange(
                DATA_ATTRIBUTES.NAME,
                e.target.value,
                e,
              )}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Input
              required={isNew}
              name={DATA_ATTRIBUTES.PASSWORD}
              label={DATA_LABELS[DATA_ATTRIBUTES.PASSWORD]}
              value={user[DATA_ATTRIBUTES.PASSWORD] ?? ''}
              onChange={(e) => this.onValueChange(
                DATA_ATTRIBUTES.PASSWORD,
                e.target.value,
                e,
              )}
              type="password"
            />
            <Form.Input
              required={isNew || !!user[DATA_ATTRIBUTES.PASSWORD]}
              name={DATA_ATTRIBUTES.PASSWORD_REPEAT}
              label={DATA_LABELS[DATA_ATTRIBUTES.PASSWORD_REPEAT]}
              value={user[DATA_ATTRIBUTES.PASSWORD_REPEAT] ?? ''}
              onChange={(e) => this.onValueChange(
                DATA_ATTRIBUTES.PASSWORD_REPEAT,
                e.target.value,
                e,
              )}
              type="password"
            />
          </Form.Group>
          {!isCurrentUser && (
            <Form.Group widths={2}>
              <Form.Checkbox
                name={DATA_ATTRIBUTES.IS_ADMIN}
                label={DATA_LABELS[DATA_ATTRIBUTES.IS_ADMIN]}
                checked={user[DATA_ATTRIBUTES.IS_ADMIN] ?? false}
                onChange={(e, data) => this.onValueChange(
                  DATA_ATTRIBUTES.IS_ADMIN,
                  data.checked,
                  e,
                )}
              />
              {!isNew && (
                <Form.Checkbox
                  name={DATA_ATTRIBUTES.IS_ACTIVE}
                  label={DATA_LABELS[DATA_ATTRIBUTES.IS_ACTIVE]}
                  checked={user[DATA_ATTRIBUTES.IS_ACTIVE] ?? false}
                  onChange={(e, data) => this.onValueChange(
                    DATA_ATTRIBUTES.IS_ACTIVE,
                    data.checked,
                    e,
                  )}
                />
              )}
            </Form.Group>
          )}
        </Form>
      </Modal>
    );
  }
}

export default EditUserModal;
