const { localStorage: storage } = window;

const TOKEN_KEY = 'auth_jwt';

function getToken() {
  return storage.getItem(TOKEN_KEY);
}

function setToken(token) {
  storage.setItem(TOKEN_KEY, token);
}

function removeToken() {
  storage.removeItem(TOKEN_KEY);
}

export default {
  getToken,
  setToken,
  removeToken,
};
