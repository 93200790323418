import React from 'react';
import { Button } from 'semantic-ui-react';
import './HeaderButton.scss';

function HeaderButton(props) {
  const { children, ...forwaredProps } = props;

  return (
    <Button
      className="header-button"
      {...forwaredProps}
    >
      {children}
    </Button>
  );
}

export default HeaderButton;
