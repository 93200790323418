import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import slice from "./slice";
import api from "../../api/api";

const { actions } = slice;

function* getLookupsSaga({ payload = {} }) {
  try {
    const { types } = payload;
    const response = yield call(api.lookups.getLookupTypes, { types });

    yield put(actions.getDataSuccess(response.data));
  } catch (e) {
    yield put(actions.getDataFailure());
  }
}

export default function* lookupsSaga() {
  yield takeEvery(actions.getDataRequest, getLookupsSaga);
}
