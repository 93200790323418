import React from 'react';
import './DropdownFilter.scss';
import { Dropdown, Menu } from 'semantic-ui-react';

function DropdownFilter(props) {
  return (
    <Menu compact className="dropdown-filter">
      <Menu.Item>
        Filter:
      </Menu.Item>
      <Menu.Item>
        <Dropdown {...props} />
      </Menu.Item>
    </Menu>
  );
}

export default DropdownFilter;
