import axios from 'axios';
import qs from 'qs';

const mappings = {
  getDetail: async ({ organizationId }) => (
    axios.get(`/mappings/${organizationId}`)
  ),
  save: async ({ organizationId, data }) => (
    axios.put(`/mappings/${organizationId}`, data)
  ),
  getList: async (query = {}) => (
    axios.get(`/mappings?${qs.stringify(query)}`)
  ),
  deleteSplitMethod: async ({ organizationId, data }) => (
    axios.put(`/mappings/${organizationId}/${data.parentId}`, data)
  ),
  saveSplit: async ({ data }) => (
    axios.put(`/mappings/${data[0].organization_id}/${data[0].mapping_pricing_payout_method_id ? data[0].mapping_pricing_payout_method_id : data[0].mapping_pricing_payment_method_id}/${data[0].payout_method_id ? data[0].payout_method_id : data[0].payment_method_id}`, data)
  ),
};
export default mappings;
