import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import organizationsListSlice from './slice';
import api from '../../api/api';

const { actions } = organizationsListSlice;

function* getOrganizationsSaga({ payload = {} }) {
  try {
    const organizationsListResponse = yield call(
      api.organizations.getList,
      payload,
    );

    yield put(actions.getOrganizationsSuccess({
      list: organizationsListResponse.data.list,
      pages: organizationsListResponse.data.pages,
    }));
  } catch (e) {
    yield put(actions.getOrganizationsFailure());
  }
}

export default function* organizationsListSaga() {
  yield takeLatest(
    actions.getOrganizationsRequest,
    getOrganizationsSaga,
  );
}
