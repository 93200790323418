import React from 'react';
import {Button, Input} from 'semantic-ui-react';
import './SearchInput.scss';

class SearchInput extends React.Component {
    static getDefaultState = (defaultValue) => ({
        search: defaultValue || '',
        isApplied: !!defaultValue,
    });

    constructor(props) {
        super(props);

        this.state = SearchInput.getDefaultState(props.defaultValue);
    }

    componentDidUpdate(prevProps, prevState) {
        const {isSyncToDefault} = this.props;
        if (!isSyncToDefault) return;

        const {defaultValue: prevDefault} = prevProps;
        const {search: prevSearch, isApplied: prevIsApplied} = prevState;
        const {defaultValue: curDefault} = this.props;
        const {search: curSearch, isApplied: curIsApplied} = this.state;

        if (
            prevSearch === curSearch &&
            prevIsApplied === curIsApplied &&
            prevDefault !== curDefault
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(SearchInput.getDefaultState(curDefault));
        }
    }

    onSearchChange = (e) => {
        this.setState({
            search: e.target.value,
        });
    };

    onSearchClick = () => {
        const {search} = this.state;
        const {onChange} = this.props;

        onChange(search);
        this.setState({
            isApplied: !!search.length,
        });
    };

    onResetSearchClick = () => {
        const {isApplied} = this.state;
        const {onChange} = this.props;

        if (isApplied) onChange('');
        this.setState({
            search: '',
            isApplied: false,
        });
    };

    render() {
        const {placeholder, dataqa} = this.props;
        const {search} = this.state;

        return (
            <Input
                className="search-input"
                placeholder={placeholder}
                value={search}
                data-qa={dataqa}
                onChange={this.onSearchChange}
                action
            >
                <input/>
                {search.length ? (
                    <Button data-qa="close" icon="close" onClick={this.onResetSearchClick}/>
                ) : null}
                <Button data-qa="search" icon="search" onClick={this.onSearchClick}/>
            </Input>
        );
    }
}

SearchInput.defaultProps = {
    debounceTime: 500,
    placeholder: 'Search...',
};

export default SearchInput;
