import { all } from "redux-saga/effects";
import organizationMappingSaga from "./organizationMapping/saga";
import organizationsMappingsListSaga from "./organizationsMappingsList/saga";
import authSaga from "./auth/saga";
import usersListSaga from "./usersList/saga";
import mappingAuditSaga from "./mappingAudit/saga";
import organizationsListSaga from "./organizationsList/saga";
import organizationEditSaga from "./organizationEdit/saga";

import lookupsSaga from "./lookups/saga";
import errorHandlerSaga from "./errorHandler/saga";

export default function* rootSaga() {
  yield all([
    organizationMappingSaga(),
    organizationsMappingsListSaga(),
    authSaga(),
    usersListSaga(),
    mappingAuditSaga(),
    organizationsListSaga(),
    organizationEditSaga(),
    lookupsSaga(),
    errorHandlerSaga(),
  ]);
}
