import { combineReducers } from "@reduxjs/toolkit";
import organizationMappingSlice from "./organizationMapping/slice";
import organizationsMappingsListSlice from "./organizationsMappingsList/slice";
import authSlice from "./auth/slice";
import usersListSlice from "./usersList/slice";
import mappingAuditSlice from "./mappingAudit/slice";
import organizationsListSlice from "./organizationsList/slice";
import organizationEditSlice from "./organizationEdit/slice";
import lookupsSlice from "./lookups/slice";

import errorHandlerSlice from "./errorHandler/slice";

export default () =>
  combineReducers({
    [organizationMappingSlice.name]: organizationMappingSlice.reducer,
    [organizationsMappingsListSlice.name]:
      organizationsMappingsListSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [usersListSlice.name]: usersListSlice.reducer,
    [mappingAuditSlice.name]: mappingAuditSlice.reducer,
    [organizationsListSlice.name]: organizationsListSlice.reducer,
    [organizationEditSlice.name]: organizationEditSlice.reducer,
    [lookupsSlice.name]: lookupsSlice.reducer,
    [errorHandlerSlice.name]: errorHandlerSlice.reducer,
  });
