import axios from 'axios';
import qs from 'qs';

const users = {
  getUsers: async (query = {}) => (
    axios.get(`/users?${qs.stringify(query)}`)
  ),
  createUser: async ({ data }) => (
    axios.post('/users', data)
  ),
  updateUser: async ({ data, id }) => (
    axios.put(`/users/${id}`, data)
  ),
  deleteUser: async ({ id }) => (
    axios.delete(`/users/${id}`)
  ),
};

export default users;
