import debounce from 'lodash/debounce';
import find from 'lodash/find';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'semantic-ui-react';
import api from '../api/api';

const displayName = 'GoogleTrendsTopicLookup';

export default Object.assign(
  React.memo((props) => {
    const { googleTrendsTopic, onValueChange, ...restProps } = props;
    const [search, setSearch] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(googleTrendsTopic ? googleTrendsTopic.mid : null);
    const [selectedOption, setSelectedOption] = useState(googleTrendsTopic ? googleTrendsTopic : null);
    const [fetchedOptions, setFetchedOptions] = useState([]);

    const findOptions = useCallback((keyword) => {
      if (!keyword || keyword.length === 1) {
        setIsLoading(false);
        return Promise.resolve(fetchedOptions);
      }
      setIsLoading(true);

      return api.googleTrendsTopics.getTopicsByKeyword(keyword)
        .then(
          ({ data }) => {
            setIsLoading(false);
            return (data);
          },
          (error) => {
            setIsLoading(false);
            setError(error);
          }
        )
    }, [setIsLoading, setError, fetchedOptions]);

    const options = useMemo(() => {
      const options = [{
        key: null,
        value: null,
        text: '-- Select topic --',
      }];
      options.push(...fetchedOptions.map((o) => ({
        key: o.mid,
        value: o.mid,
        text: `${o.title} - ${o.type}`,
      })));

      if (selectedOption && !find(options, { value: selectedOption.mid })) {
        const { mid, title, type } = selectedOption;

        options.push({
          key: mid,
          value: mid,
          text: `${title} - ${type}`,
        });
      }

      return options;
    }, [selectedOption, fetchedOptions]);

    const delayedQuery = useCallback(
      debounce((keyword) => findOptions(keyword).then(setFetchedOptions), 700),
      [findOptions, setFetchedOptions],
    );

    useEffect(() => {
      if (search !== null) {
        delayedQuery(search);
      }
    }, [search, delayedQuery]);

    const handleChange = (_e, { value: newValue }) => {
      setValue(newValue)

      const selectedOpn = newValue
        ? [...fetchedOptions, selectedOption].find(({ mid }) => mid === newValue)
        : null;
      setSelectedOption(selectedOpn)

      setSearch(null);

      onValueChange(selectedOpn);
    }

    return (
      <Form.Select
        {...restProps}
        options={options}
        loading={isLoading}
        value={value}
        searchQuery={search || ''}
        onSearchChange={(e) => setSearch(e.target.value)}
        placeholder="Start to write a keyword..."
        onChange={handleChange}
        search={(i) => i}
        error={error}
      />
    );
  }),
  { displayName },
);
