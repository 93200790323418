import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  list: [],
  pages: 1,
};

const mappingAuditSlice = createSlice({
  name: 'mappingAudit',
  initialState,
  reducers: {
    getAuditRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getAuditSuccess: (state, { payload }) => ({
      ...state,
      isLoading: false,
      list: [...payload.list],
      pages: payload.pages,
    }),
    getAuditFailure: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
});

export default mappingAuditSlice;
