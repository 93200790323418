import React, {createRef} from 'react';
import {connect} from 'react-redux';
import Select, {components} from 'react-select';
import {Button, Grid, Header, Input, Sticky, Message} from 'semantic-ui-react';
import cn from 'classnames';
import organizationMappingSlice from '../store/organizationMapping/slice';
import {
    ATTRIBUTE_SUBTYPE_LABELS,
    ATTRIBUTE_TYPE_LABELS,
    ATTRIBUTE_TYPE_MAPPING_DATA,
    KNOWN_ATTRIBUTE_TYPES,
} from '../constants/constants';
import './OrganizationMapping.scss';
import SortableTable from '../components/SortableTable';
import DataFilter from '../components/DataFilter';
import Loader from '../components/Loader';
import CreateMappingModal, {DATA_ATTRIBUTES as NEW_MAPPING_DATA_ATTRIBUTES,} from './CreateMappingModal';
import DropdownFilter from '../components/DropdownFilter';
import lookupsSlice from "../store/lookups/slice";
import { v4 as uuidv4 } from 'uuid';
import UnknownAttribute from '../components/UnknownAttribute';
import withRouter from '../withRouter';

const COLUMNS = {
    ATTRIBUTE_TYPE: 'ATTRIBUTE_TYPE',
    ATTRIBUTE_SUBTYPES: 'ATTRIBUTE_SUBTYPES',
    RAW_DATA: 'RAW_DATA',
    PRIMARY_MAPPING: 'PRIMARY_MAPPING',
    URL: 'URL',
};

const lookupOptionsCache = {};

class OrganizationMapping extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
          addDropDown: false,
          numChildren: 0,
          splitChildren: [],
          dropdownIds: [],
          splits: [],
          parentValues: [],
          parentErrors: false
      }
    }
    static columnsConfig = {
        columns: [
            COLUMNS.ATTRIBUTE_TYPE,
            COLUMNS.RAW_DATA,
            COLUMNS.PRIMARY_MAPPING,
        ],
        keyAttribute: 'meta.key',
        defaultText: '-',
        config: {
            [COLUMNS.ATTRIBUTE_TYPE]: {
                label: 'Attribute Type',
                sortable: true,
                dataAttribute: 'type',
                customRender: true,
            },
            [COLUMNS.RAW_DATA]: {
                label: 'Raw Data',
                sortable: true,
                dataAttribute: 'mapping_value',
                customRender: true,
            },
            [COLUMNS.PRIMARY_MAPPING]: {
                label: 'Primary Mapping',
                dataAttribute: 'mapping_id',
                newDataAttribute: 'split',
                customRender: true,
            },
            [COLUMNS.CORRIDOR]: {
                label: 'Corridor',
                dataAttribute: 'corridor',
                customRender: true,
            },
            [COLUMNS.ATTRIBUTE_SUBTYPES]: {
                dataAttribute: 'subtypes',
            },
        },
    };

    static filterConfig = {
        options: [
            {
                text: 'Not Selected',
                value: false,
            },
            ...Object
                .entries(ATTRIBUTE_TYPE_LABELS)
                .map(([key, label]) => ({
                    text: label,
                    value: key,
                })),
        ],
        defaultValue: false,
        attribute: OrganizationMapping
            .columnsConfig
            .config[COLUMNS.ATTRIBUTE_TYPE]
            .dataAttribute,
    };

    contentRef = createRef()

    componentDidMount() {
        this.getData();
        this.getLookupData();
    }

    componentWillUnmount() {
        const {resetState} = this.props;
        resetState();
    }

    isLoading() {
        const {
            isLoading,
            lookupData,
        } = this.props;

        return Boolean(
            isLoading &&
            Object.values(ATTRIBUTE_TYPE_MAPPING_DATA).filter(name => lookupData[name].length !== 0).length === 0
        );
    }

    getData = () => {
        const {
            getDataRequest,
            params: {organizationId},
        } = this.props;
        getDataRequest({organizationId});
    };

    getLookupData() {
        const {
            getLookupRequest,
            lookupData,
        } = this.props;

        const lookupTypes = Object.values(ATTRIBUTE_TYPE_MAPPING_DATA).filter(name => lookupData[name].length === 0);
        if (lookupTypes.length > 0) {
            getLookupRequest({types: lookupTypes});
        }
    }

    saveData = () => {
        const {
            saveDataRequest,
            params: {organizationId},
        } = this.props;
        saveDataRequest({organizationId});
    };
    saveSplits = () => {
        const {saveSplit} = this.props;
        let allItems = []
        for (let item of this.state.splits) {
            allItems.push(item)
            
        }
        saveSplit(allItems)
            this.setState({
                splits: [],
                splitChildren: [],
        })
    }

    onItemDataChange(column, item, data) {
        const {setDataValue} = this.props;
        
        const attribute = OrganizationMapping
            .columnsConfig.config[column].dataAttribute;

        setDataValue({
            item,
            attribute,
            value: data.value,
        });
    };

    onNewItemDataChange(id, item, key, results, splitMethod, parentKey) {
        const {params: {organizationId}} = this.props;

        let mappingPricingId;
        let splitMethodId;
        
        if(splitMethod == 'payout') {
            mappingPricingId = 'mapping_pricing_payout_method_id';
            splitMethodId = 'payout_method_id';
        } else {
            mappingPricingId = 'mapping_pricing_payment_method_id';
            splitMethodId = 'payment_method_id'
        }

        this.setState({
            splits: this.state.splits.map(el => (el.key === key ? {...el, [splitMethodId]: item.value, [mappingPricingId]: id, organization_id: organizationId, parentKey: parentKey, label: item.label} : el))
        });
    };

    renderRawDataItem = (column, item) => {
        const {dataAttribute} = OrganizationMapping
            .columnsConfig.config[column];

        return (
            <Input
                fluid
                value={item[dataAttribute]}
                onChange={(e, data) => this.onItemDataChange(column, item, data)}
            />
        );
    };
    removePaymentMethod = (item) => {
        const {deleteMethodsRequest} = this.props;
        deleteMethodsRequest({item})
    }

    renderAttributeTypeItem = (column, item) => {
        const typeAttribute = OrganizationMapping
            .columnsConfig.config[column].dataAttribute;
        const subtypesAttribute = OrganizationMapping
            .columnsConfig.config[COLUMNS.ATTRIBUTE_SUBTYPES].dataAttribute;

        return (!item[subtypesAttribute] || !item[subtypesAttribute].length)
            ? (ATTRIBUTE_TYPE_LABELS[item[typeAttribute]] || item[typeAttribute])
            : item[subtypesAttribute]
                .map((subtype) => ATTRIBUTE_SUBTYPE_LABELS[subtype]).join(', ');
    };
    removeSplit = (id, result) => {
        let splitMethodId;

        if(result[0]['type'] == 'payout_method') {
            splitMethodId = 'payout_method_id';
        } else {
            splitMethodId = 'payment_method_id'
        }

        const idPosition = this.state.splits.findIndex(el => el.key === id);
        if (idPosition >= 0) {
            this.state.splits.splice(idPosition, 1);
        }
        const idChildrenPosition = this.state.splitChildren.findIndex(el => el.key === id);
        if (idChildrenPosition >= 0) {
            this.state.splitChildren.splice(idChildrenPosition, 1);
        }
        this.setState({
            splitChildren: this.state.splitChildren.filter(item => item.key != id)
        })
        
    }
   
    splitMapping = (dropdownId, parentKey, type) => {
        const {paymentMethods, payoutMethods} = this.props;
        const paymentMethodOptions = paymentMethods.map((dataItem) => ({
            label: dataItem.name,
            value: dataItem.id,
            key: dataItem.meta.key
        }));
        const payoutMethodOptions = payoutMethods.map((dataItem) => ({
            label: dataItem.name,
            value: dataItem.id,
            key: dataItem.meta.key
        }));

        const result = this.props.organizationData ? this.props.organizationData.filter( (item) => item.id === dropdownId && item.type === type) : ''
        const uniqueID = uuidv4();
        const selectOptions = <React.Fragment key={uniqueID}>
            <div className="ten wide column">
                <Select
                    placeholder="Select Mapping..."
                    className="selectbox"
                    classNamePrefix="selectbox"
                    options={result[0].type == 'payout_method' ? payoutMethodOptions : paymentMethodOptions}
                    onChange={(data) => this.onNewItemDataChange(dropdownId, data, uniqueID, result, result[0].type == 'payout_method' ? 'payout' : 'payment', parentKey)}
                    isSearchable
                    parentID={dropdownId} 
                    type={type}
                />
            </div>
            <div className="two wide column"><button className={'deleteMapping'} onClick={() => this.removeSplit(uniqueID, parentKey, result)}>x</button></div>
        </React.Fragment>;
        this.setState(prevState => ({
            dropdownIds: [ {results: result, id: dropdownId, organizationId: this.props.organizationInfo.id}, ...prevState.dropdownIds],
            splitChildren: [...this.state.splitChildren, selectOptions],
            splits: [...this.state.splits, {parentKey: parentKey, key: uniqueID}],
            numChildren: this.state.numChildren + 1
        }))

    }

    getLookupOptions(lookupType) {
        if (this.props.lookupData[lookupType].length === 0) {
            return [];
        }
        if (lookupType in lookupOptionsCache) {
            return lookupOptionsCache[lookupType];
        }
        if (lookupType === 'zipCodes') {
            const states = Object.fromEntries(this.props.lookupData['states'].map((state) => [state.id, state.code]));
            lookupOptionsCache[lookupType] = this.props.lookupData[lookupType].map((zipCode) => ({
                label: `${states[zipCode.state_id]} ${zipCode.code}`,
                value: zipCode.id,
            }));
        } else if (lookupType === 'states') {
            const countries = Object.fromEntries(this.props.lookupData['countries'].map((country) => [country.id, country.iso2_code]));
            lookupOptionsCache[lookupType] = this.props.lookupData[lookupType].map((state) => ({
                label: `${countries[state.country_id]} ${state.name} (${state.code})`,
                value: state.id,
            }));
        } else {
            lookupOptionsCache[lookupType] = this.props.lookupData[lookupType].map((dataItem) => ({
                label: dataItem.name,
                value: dataItem.id,
            }));
        }
        return lookupOptionsCache[lookupType];
    }

    renderMappingItem = (column, item) => {
        const {setNewMappingData, paymentMethods, payoutMethods, splitPayoutMethods, splitPaymentMethods} = this.props;
        const typeAttribute = OrganizationMapping
            .columnsConfig.config[COLUMNS.ATTRIBUTE_TYPE].dataAttribute;
        const lookupDataAttribute = ATTRIBUTE_TYPE_MAPPING_DATA[item[typeAttribute]];
        const itemAttribute = OrganizationMapping
            .columnsConfig.config[column].dataAttribute;
        const organizationId = this.props.organizationInfo.id;

        const options = this.getLookupOptions(lookupDataAttribute);
        const selectedOption = options
            .find((optionItem) => optionItem.value === item[itemAttribute]) || null;

        const paymentMethodOptions = paymentMethods.map((dataItem) => ({
            label: dataItem.name,
            value: dataItem.id,
        }));
        const payoutMethodOptions = payoutMethods.map((dataItem) => ({
            label: dataItem.name,
            value: dataItem.id,
        }));
        const splitPayoutMethodOptions = splitPayoutMethods.map((dataItem) => ({
            label: dataItem.name,
            value: dataItem.payout_method_id,
            organization_id: dataItem.organization_id,
            splitMappingId: dataItem.id,
            uniqueKey: dataItem.meta.key,
        }));
        const splitPaymentMethodOptions = splitPaymentMethods.map((dataItem) => ({
            label: dataItem.name,
            value: dataItem.payment_method_id,
            organization_id: dataItem.organization_id,
            splitMappingId: dataItem.id,
            uniqueKey: dataItem.meta.key,
        }));
        return (
            <div className="ui grid" id={item.id}>
              <div className="ten wide column">
              <Select
                  placeholder="Select Mapping..."
                  className="selectbox"
                  classNamePrefix="selectbox"
                  value={selectedOption}
                  options={options}
                  onChange={(data) => this.onItemDataChange(column, item, data, item.meta.key)}
                  components={{
                      Menu: (props) => (
                          <components.Menu {...props}>
                              {props.children}
                              {
                                  ![
                                      KNOWN_ATTRIBUTE_TYPES.COUNTRY_FROM,
                                      KNOWN_ATTRIBUTE_TYPES.COUNTRY_TO,
                                      KNOWN_ATTRIBUTE_TYPES.STATE_FROM,
                                      KNOWN_ATTRIBUTE_TYPES.STATE_TO,
                                      KNOWN_ATTRIBUTE_TYPES.ZIP_CODE_FROM,
                                      KNOWN_ATTRIBUTE_TYPES.ZIP_CODE_TO,
                                      KNOWN_ATTRIBUTE_TYPES.TRANSFER_SPEED,
                                  ].includes(item[typeAttribute])
                                      ? (
                                          <div
                                              onClick={() => setNewMappingData({
                                                  attribute: NEW_MAPPING_DATA_ATTRIBUTES.TYPE,
                                                  value: item[typeAttribute],
                                              })}
                                              className="custom-option"
                                              data-qa="create-new"
                                          >
                                              Create New
                                          </div>
                                          
                                      ) : null
                              }
                          </components.Menu>
                      ),
                  }}
                  isSearchable
              />
              </div>
              { item.type === KNOWN_ATTRIBUTE_TYPES.PAY_OUT_METHOD && item.id || item.type === KNOWN_ATTRIBUTE_TYPES.PAY_IN_METHOD && item.id
              ? <div className="two wide column"><button onClick={() => this.splitMapping(item.id, item.meta.key, item.type)} className="ui button">Split</button></div>
              : ''}
              {item.type === KNOWN_ATTRIBUTE_TYPES.PAY_OUT_METHOD
                ? <>
                { splitPayoutMethodOptions.map(element => 
                    element.splitMappingId == item.id ? <React.Fragment key={element.uniqueKey}>
                        <div className="ten wide column">   
                            <Select
                                noOptionsMessage={() => null}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                placeholder={element.label}
                                className="selectbox"
                                classNamePrefix="selectbox"
                                onChange={(data) => this.onNewItemDataChange(data, item.meta.key)}
                            />
                        </div>
                        <div className="two wide column"><button className={'deleteMapping'} onClick={() => 
                            this.removePaymentMethod(payoutMethodOptions 
                                ? { parentId: element.splitMappingId, paymentMethod: 'payoutMethod', organizationId: organizationId, splitMappingId: element.value } 
                                : null)}>x</button>
                        </div>
                    </React.Fragment>
                    : ''
                )}
                </> : ''
              }
              {item.type === KNOWN_ATTRIBUTE_TYPES.PAY_IN_METHOD
                ? <>
                { splitPaymentMethodOptions.map(element => 
                    element.splitMappingId == item.id ? <React.Fragment key={element.uniqueKey}>
                        <div className="ten wide column">   
                            <Select
                                placeholder={element.label}
                                className="selectbox"
                                classNamePrefix="selectbox"
                                options={paymentMethodOptions}
                                onChange={(data) => this.onNewItemDataChange(data, item.meta.key)}
                                isSearchable
                            />
                        </div>
                        <div className="two wide column"><button className={'deleteMapping'} onClick={() => 
                            this.removePaymentMethod(paymentMethodOptions 
                                ? { parentId: element.splitMappingId, paymentMethod: 'paymentMethod', organizationId: organizationId, splitMappingId: element.value } 
                                : null)}>x</button>
                        </div>
                    </React.Fragment>
                    : ''
                )}
                </> : ''
              }
              
              {
                  this.state.splitChildren.map(results => {
                      if(results.props.children[0].props.children.props.parentID === item.id && results.props.children[0].props.children.props.type === item.type) {
                        return results
                      }
                  })
              }
            </div>
        );
    };

    renderCorridors = (column, item) => {
        const itemAttribute = OrganizationMapping
            .columnsConfig.config[column].dataAttribute;
        const value = item[itemAttribute];
        if (!value) {
            return '-';
        }
        return value.slice(0, 2).join(', ') + (value.length > 2 ? ` and ${value.length - 2} more...` : '');
    };

    isUnknownAttributeType(item) {
        const typeAttribute = OrganizationMapping
            .columnsConfig.config[COLUMNS.ATTRIBUTE_TYPE].dataAttribute;

        return !Object.values(KNOWN_ATTRIBUTE_TYPES).includes(item[typeAttribute])
    }

    renderItem = (column, item) => {
        switch (column) {
            case COLUMNS.ATTRIBUTE_TYPE:
                return this.renderAttributeTypeItem(column, item);
            case COLUMNS.RAW_DATA:
                return this.renderRawDataItem(column, item);
            case COLUMNS.PRIMARY_MAPPING:
                return this.isUnknownAttributeType(item) ? <UnknownAttribute 
                    renderMappingItem={this.renderMappingItem}
                    column={column}
                    item={item}
                /> : this.renderMappingItem(column, item);
                
            default:
                return null;
        }
    };


    render() {
        const {
            organizationData,
            organizationInfo,
            newMapping,
            setNewMappingData,
            resetNewMappingData,
            saveNewMappingRequest,
            params: { organizationId },
        } = this.props;

        const isChanged = organizationData.some((item) => (
            item.meta && item.meta.changed
        ));

        const isSplitAdded = Object.keys(this.state.splits).length;
        const isSaveSplitsAllowed = this.state.splits.filter(element => element.label !== undefined)
        return (
            <div className="organization-mapping" ref={this.contentRef}>
                {this.isLoading() ? (
                    <Loader isLoading={true}/>
                ) : (
                    <DataFilter items={organizationData}>
                        {(filteredItems, setFilter) => (
                            <>
                                <CreateMappingModal
                                    isOpen={!!Object.keys(newMapping).length}
                                    data={newMapping}
                                    onValueChange={setNewMappingData}
                                    onCancel={resetNewMappingData}
                                    onCreate={saveNewMappingRequest}
                                />
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column className="flex align-items-center">
                                            <Header size="large" className="organization-header">
                                                <span>{organizationInfo.name}</span>
                                                <span className="organization-id">
                                                    #{organizationId}
                                                </span>
                                            </Header>
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            <DropdownFilter
                                                options={OrganizationMapping.filterConfig.options}
                                                defaultValue={OrganizationMapping.filterConfig.defaultValue}
                                                onChange={(e, data) => setFilter(
                                                    OrganizationMapping.filterConfig.attribute,
                                                    data.value ? data.value : null,
                                                )}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Sticky context={this.contentRef}>
                                    <Grid attached="top">
                                        <Grid.Row>
                                            <Grid.Column className="action-buttons">
                                                <Button
                                                    color="red"
                                                    disabled={!isChanged}
                                                    onClick={this.getData}
                                                    data-qa="revert-changes"
                                                >
                                                    Revert Changes
                                                </Button>
                                                <Button
                                                    color="blue"
                                                    disabled={!isChanged}
                                                    onClick={this.saveData}
                                                    data-qa="save-changes"
                                                >
                                                    Save Changes
                                                </Button>
                                                {isSplitAdded > 0 && this.state.splitChildren.length > 0 && 
                                                !isChanged &&
                                                isSaveSplitsAllowed.length > 0 ? <Button
                                                    color="green"
                                                    disabled={!isSplitAdded}
                                                    onClick={this.saveSplits}
                                                    data-qa="save-changes"
                                                >{isSplitAdded > 1 ? 'Save Splits' : 'Save Split'}</Button>
                                                : ''
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Sticky>
                                <SortableTable
                                    items={filteredItems}
                                    columnsConfig={OrganizationMapping.columnsConfig}
                                    renderItem={this.renderItem}
                                    setRowClassName={(item) => cn({
                                        warning: item.meta && item.meta.changed,
                                    })}
                                />
                            </>
                        )}
                    </DataFilter>
                )}
            </div>
        );
    }
}


const {
    setDataValue,
    setNewDataValue,
    getDataRequest,
    saveDataRequest,
    deleteMethodsRequest,
    saveSplit,
    resetState,
    setNewMappingData,
    resetNewMappingData,
    saveNewMappingRequest,
} = organizationMappingSlice.actions;

const {
    getDataRequest: getLookupRequest,
} = lookupsSlice.actions;

export default connect(
    ({
         [organizationMappingSlice.name]: {
             paymentMethods,
             payoutMethods,
             splitPayoutMethods,
             splitPaymentMethods,
             organizationData,
             organizationInfo,
             isLoading,
             newMapping,
         },
         [lookupsSlice.name]: lookupData
     }) => ({
        paymentMethods,
        payoutMethods,
        splitPayoutMethods,
        splitPaymentMethods,
        organizationData,
        lookupData,
        organizationInfo,
        isLoading,
        newMapping,
    }),
    {
        setDataValue,
        setNewDataValue,
        getDataRequest,
        saveDataRequest,
        deleteMethodsRequest,
        saveSplit,
        resetState,
        setNewMappingData,
        resetNewMappingData,
        saveNewMappingRequest,
        getLookupRequest,
    },
)(withRouter(OrganizationMapping));