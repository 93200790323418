import orderBy from 'lodash/orderBy';
import remove from 'lodash/remove';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid } from 'semantic-ui-react';
import GoogleTrendsTopicLookup from '../components/GoogleTrendsTopicsLookup';
import Loader from '../components/Loader';
import lookupsSlice from '../store/lookups/slice';
import organizationEditSlice from '../store/organizationEdit/slice';
import './OrganizationEdit.scss';
import withRouter from '../withRouter';

class OrganizationEdit extends React.Component {
    static fields = [
        {
            required: true,
            name: 'name',
            label: 'Name',
        },
        {
            required: true,
            name: 'organization_type_id',
            label: 'Type',
            type: 'lookup',
            source: 'organizationTypes',
        },
        {
            name: 'is_pricing_displayed',
            label: 'Enabled for pricing FX API',
            type: 'checkbox',
        },
        {
            required: true,
            name: 'home_country_id',
            label: 'Home Country',
            type: 'lookup',
            source: 'countries',
            props: {
                search: true,
            },
        },
        {
            name: "google_trends_topic",
            label: "Google Trends Topic",
            type: "google_trends_topic",
        },
        {
            name: 'home_currency_id',
            label: 'Home Currency',
            type: 'lookup',
            source: 'currencies',
            props: {
                search: true,
                clearable: true,
            },
        },
        {
            name: 'duns_number',
            label: 'Dun and bradstreet number',
        },
        {
            name: 'address',
            label: 'Address',
        },
        {
            name: 'city',
            label: 'City',
        },
        {
            name: 'zip_code',
            label: 'Zip Code',
        },
        {
            name: 'phone_number',
            label: 'Phone',
            props: {
                type: 'tel',
            },
        },
        {
            name: 'email',
            label: 'E-mail',
            props: {
                type: 'email',
            },
        },
        {
            name: 'website',
            label: 'Website',
            props: {
                type: 'url',
            },
        },
    ];

    static defaultValues = {
        checkbox: false,
        lookup: null,
        others: '',
        google_trends_topic: null
    }

    componentDidMount() {
        this.getData();
        this.getLookupData();
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    componentDidUpdate(prevProps) {
        const { isDuplicate, currentData, setDataValue, countries } = this.props;
        if (isDuplicate && !prevProps.isDuplicate) {
            const { name, home_country_id } = currentData;
            const country = countries.find(c => c.id === home_country_id);
            const proposedName = `${name} ${country.iso2_code}`;
            if (window.confirm(`Organization with this name (${name}) already exists. Proposed name: ${proposedName}. Apply?`)) {
                setDataValue({
                    name: proposedName,
                });
                setTimeout(() => this.saveData(), 0);
            }
        }
    }

    isLoading() {
        return Boolean(
            this.props.isLoading ||
            this.props.organizationTypes.length === 0 ||
            this.props.countries.length === 0 ||
            this.props.currencies.length === 0,
        );
    }

    resetForm = () => {
        const {
            params: { id },
        } = this.props;

        if (window.confirm('Do you want to reset the form?')) {
            if (!id) {
                this.props.resetState();
            } else {
                this.getData();
            }
        }
    }

    getData = () => {
        const {
            getDataRequest,
            params: { id }
        } = this.props;

        if (id) {
            getDataRequest({ id });
        }
    };

    getLookupData() {
        const {
            getLookupRequest,
            organizationTypes,
            currencies,
            countries,
        } = this.props;

        const lookupTypes = [];
        if (organizationTypes.length === 0) {
            lookupTypes.push('organizationTypes');
        }
        if (countries.length === 0) {
            lookupTypes.push('countries');
        }
        if (currencies.length === 0) {
            lookupTypes.push('currencies');
        }
        if (lookupTypes.length > 0) {
            getLookupRequest({ types: lookupTypes });
        }
    }

    saveData = () => {
        const {
            saveDataRequest,
            initialData,
            currentData,
            params: { id },
        } = this.props;
        const data = Object.fromEntries(
            Object.entries(currentData)
                .filter(([key, value]) => initialData[key] !== value),
        );

        // set default values for new organization
        if (!id) {
            const { defaultValues } = OrganizationEdit;
            OrganizationEdit.fields.forEach((field) => {
                if (typeof data[field.name] !== 'undefined') {
                    return;
                }
                data[field.name] = typeof defaultValues[field.type] !== 'undefined' ? defaultValues[field.type] : defaultValues.others;
            });
        }

        saveDataRequest({ id, data });
    };

    onValueChange = (attr, value) => {
        const { setDataValue } = this.props;
        setDataValue({
            [attr]: value,
        });
    };

    renderField(field) {
        switch (field.type) {
            case "google_trends_topic":
                return (
                    <GoogleTrendsTopicLookup
                        {...field.props}
                        // multiple
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        required={!!field.required}
                        onValueChange={(value) => {
                            this.onValueChange(field.name, value);
                        }}
                        googleTrendsTopic={this.props.currentData[field.name]}
                    />
                );

            case 'lookup':
                let options = this.props[field.source];
                if (field.filterOptions) {
                    options = options.filter((item) => field.filterOptions(item, this.props));
                }
                options = options.map((dataItem) => ({
                    key: `${dataItem.id}`,
                    text: dataItem.name,
                    value: dataItem.id,
                }));
                options = orderBy(options, ['text']);
                if (field.source === 'organizationTypes') {
                    // Bank and Payment Company should come first
                    // as they are more popular than others org types
                    // see https://rm.fxcompared.com/issues/17016
                    options.unshift(
                        ...remove(options, item => item.text === 'Payment Company')
                    )
                    options.unshift(
                        ...remove(options, item => item.text === 'Bank')
                    )
                }
                return (
                    <Form.Select
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        required={!!field.required}
                        className="selectbox"
                        value={this.props.currentData[field.name] ?? ''}
                        options={options}
                        onChange={(event, data) => this.onValueChange(field.name, data.value)}
                        fluid
                        selectOnBlur={false}
                        lazyLoad
                        {...field.props}
                    />
                );

            case 'checkbox':
                return (
                    <Form.Checkbox
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        required={!!field.required}
                        checked={this.props.currentData[field.name] ?? false}
                        onChange={(e, data) => this.onValueChange(
                            field.name,
                            data.checked,
                            e,
                        )}
                        {...field.props}
                    />
                );
            default:
                return (
                    <Form.Input
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        required={!!field.required}
                        value={this.props.currentData[field.name] ?? ''}
                        onChange={(e) => this.onValueChange(
                            field.name,
                            e.target.value,
                            e,
                        )}
                        {...field.props}
                        error={field.name === 'name' && this.props.isDuplicate ? 'Organization with this name already exists' : undefined}
                    />
                );
        }
    }

    render() {
        const {
            currentData,
            initialData,
        } = this.props;
        const isChanged = Object.entries(currentData).some(([key, value]) => (
            initialData[key] !== value
        ));
        const canSubmit = this.canSubmit();

        return (
            <div className="organization-edit">
                {this.isLoading() ? (
                    <Loader isLoading />
                ) : (
                    <Form onSubmit={this.saveData} id="editOrganization">
                        {OrganizationEdit.fields.map((field) => this.renderField(field))}
                        <Grid>
                            <Grid.Row>
                                <Grid.Column textAlign="right" className="action-buttons">
                                    <Button
                                        type="reset"
                                        color="red"
                                        disabled={!isChanged}
                                        onClick={this.resetForm}
                                        data-qa="revert-changes"
                                    >
                                        Revert Changes
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="blue"
                                        disabled={!canSubmit}
                                        data-qa="save-changes"
                                    >
                                        Save Changes
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}
            </div>
        );
    }

    canSubmit() {
        const {
            currentData,
        } = this.props;
        return OrganizationEdit.fields.every((field) => {
            if (field.required && (!currentData[field.name] || currentData[field.name].length === 0)) {
                return false;
            }
            return true;
        });
    }
}

const {
    setDataValue,
    getDataRequest,
    saveDataRequest,
    resetState,
} = organizationEditSlice.actions;

const {
    getDataRequest: getLookupRequest,
} = lookupsSlice.actions;

export default connect(
    ({
        [organizationEditSlice.name]: {
            initialData,
            currentData,
            isLoading,
            isDuplicate,
        },
        [lookupsSlice.name]: {
            organizationTypes,
            countries,
            currencies,
            states,
        },
    }) => ({
        initialData,
        currentData,
        isLoading,
        isDuplicate,
        organizationTypes,
        countries,
        currencies,
        states,
    }),
    {
        setDataValue,
        getDataRequest,
        saveDataRequest,
        resetState,
        getLookupRequest,
    },
)(withRouter(OrganizationEdit));
