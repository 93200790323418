import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  organizationTypes: [],
  currencies: [],
  countries: [],
  states: [],
  zipCodes: [],
  paymentMethods: [],
  payoutMethods: [],
  transferSpeeds: [],
};

const organizationEditSlice = createSlice({
  name: "lookups",
  initialState,
  reducers: {
    getDataRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getDataSuccess: (state, { payload }) => ({
      ...state,
      ...payload.data,
      isLoading: false,
    }),
    getDataFailure: (state) => ({
      ...state,
      isLoading: false,
    }),
    resetState: () => initialState,
  },
});

export default organizationEditSlice;
