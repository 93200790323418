import React, { useState } from 'react';
import Select from 'react-select';
import {
  ATTRIBUTE_TYPE_LABELS,
  KNOWN_ATTRIBUTE_TYPES,
} from '../constants/constants';

const KNOWN_ATTRIBUTE_TYPES_ARRAY = Object.values(KNOWN_ATTRIBUTE_TYPES)

function TypeSelect({ setAttributeType }) {
  const options = KNOWN_ATTRIBUTE_TYPES_ARRAY.map(attributeType => ({
    value: attributeType,
    label: ATTRIBUTE_TYPE_LABELS[attributeType]
  }))

  return (
    <div className="ui grid">
      <div className="ten wide column">
          <Select
            placeholder="Select Mapping Table..."
            className="selectbox"
            classNamePrefix="selectbox"
            onChange={({ value }) => setAttributeType(value)}
            options={options}
            isSearchable
        />
      </div>
    </div>  
  )
}

function UnknownAttribute({ renderMappingItem, column, item }) {
  const [attributeType, setAttributeType] = useState(null)

  return <>
    <p>{'It doesn\'t have a mapping table yet'}</p>
    <TypeSelect setAttributeType={setAttributeType} />
    {attributeType && renderMappingItem(column, {
      ...item,
      type: attributeType
    })}
  </>
}

export default UnknownAttribute;