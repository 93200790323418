import React from 'react';

class DataFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attribute: null,
      value: null,
    };
  }

  setFilter = (attribute, value) => {
    this.setState({
      attribute,
      value,
    });
  };

  filterItems = (items) => {
    const { attribute, value } = this.state;
    if (!attribute || value == null) return items;
    return items.filter((item) => (
      Array.isArray(item[attribute])
        ? item[attribute].includes(value)
        : item[attribute] === value
    ));
  };

  render() {
    const { items, children } = this.props;
    const filteredItems = this.filterItems(items);
    return children(filteredItems, this.setFilter);
  }
}

export default DataFilter;
