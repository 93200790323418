import React from 'react';
import { connect } from 'react-redux';
import './UsersList.scss';
import {
  Grid,
  Header,
  Pagination,
} from 'semantic-ui-react';
import cn from 'classnames';
import usersListSlice from '../store/usersList/slice';
import Loader from '../components/Loader';
import SortableTable from '../components/SortableTable';
import QueryComposer from '../components/QueryComposer';
import DropdownFilter from '../components/DropdownFilter';
import EditUserModal from './EditUserModal';
import HeaderButton from '../components/HeaderButton';
import authSlice from '../store/auth/slice';

const COLUMNS = {
  ID: 'ID',
  EMAIL: 'EMAIL',
  NAME: 'NAME',
  IS_ADMIN: 'IS_ADMIN',
  IS_ACTIVE: 'IS_ACTIVE',
  CREATED_AT: 'CREATED_AT',
  UPDATED_AT: 'UPDATED_AT',
};

class UsersList extends React.Component {
  static columnsConfig = {
    keyAttribute: 'id',
    defaultText: '-',
    columns: [
      COLUMNS.EMAIL,
      COLUMNS.ID,
      COLUMNS.NAME,
      COLUMNS.IS_ADMIN,
      COLUMNS.IS_ACTIVE,
      COLUMNS.CREATED_AT,
      COLUMNS.UPDATED_AT,
    ],
    config: {
      [COLUMNS.ID]: {
        label: 'Id',
        sortable: true,
        dataAttribute: 'id',
      },
      [COLUMNS.EMAIL]: {
        label: 'Email',
        sortable: true,
        dataAttribute: 'email',
        customRender: true,
      },
      [COLUMNS.NAME]: {
        label: 'Name',
        sortable: true,
        dataAttribute: 'name',
      },
      [COLUMNS.IS_ADMIN]: {
        label: 'Is Admin',
        sortable: true,
        dataAttribute: 'is_admin',
        customRender: true,
      },
      [COLUMNS.IS_ACTIVE]: {
        label: 'Is Active',
        sortable: true,
        dataAttribute: 'is_active',
        customRender: true,
      },
      [COLUMNS.CREATED_AT]: {
        label: 'Created At',
        sortable: true,
        dataAttribute: 'created_at',
        customRender: true,
      },
      [COLUMNS.UPDATED_AT]: {
        label: 'Updated At',
        sortable: true,
        dataAttribute: 'updated_at',
        customRender: true,
      },
    },
  };

  static filterConfig = {
    options: [
      {
        text: 'Not Selected',
        value: '_',
      },
      {
        text: 'Plain Users',
        value: 'false',
      },
      {
        text: 'Admins',
        value: 'true',
      },
    ],
    defaultValue: '_',
    attribute: UsersList
      .columnsConfig
      .config[COLUMNS.IS_ADMIN]
      .dataAttribute,
  };

  componentWillUnmount() {
    const { resetState } = this.props;
    resetState();
  }

  renderEmailColumn = (column, item) => {
    const { setEditedUser } = this.props;
    const { dataAttribute } = UsersList.columnsConfig.config[column];
    return (
      <span
        className="link-like"
        onClick={() => setEditedUser({ user: item })}
      >
        {item[dataAttribute]}
      </span>
    );
  };

  renderCheckboxColumn = (column, item) => {
    const { dataAttribute } = UsersList.columnsConfig.config[column];
    const iconClassName = cn('icon square outline checkbox-icon', {
      check: item[dataAttribute],
    });
    return (<i className={iconClassName} />);
  };

  renderDateColumn = (column, item) => {
    const { dataAttribute } = UsersList.columnsConfig.config[column];
    return new Date(item[dataAttribute]).toLocaleString();
  };

  renderItem = (column, item) => {
    switch (column) {
      case COLUMNS.EMAIL:
        return this.renderEmailColumn(column, item);
      case COLUMNS.IS_ADMIN:
      case COLUMNS.IS_ACTIVE:
        return this.renderCheckboxColumn(column, item);
      case COLUMNS.CREATED_AT:
      case COLUMNS.UPDATED_AT:
        return this.renderDateColumn(column, item);
      default:
        return null;
    }
  };

  onSaveUser = (user, fetchQuery) => {
    const { saveUserRequest } = this.props;
    const idAttribute = UsersList
      .columnsConfig
      .config[COLUMNS.ID]
      .dataAttribute;
    saveUserRequest({
      data: user,
      id: user[idAttribute],
      fetchQuery,
    });
  };

  onDeleteUser = (user, fetchQuery) => {
    const { deleteUserRequest } = this.props;
    const idAttribute = UsersList
      .columnsConfig
      .config[COLUMNS.ID]
      .dataAttribute;
    deleteUserRequest({
      id: user[idAttribute],
      fetchQuery,
    });
  };

  render() {
    const {
      list,
      pages,
      isLoading,
      getUsersRequest,
      editedUser,
      authUser,
      setEditedUser,
      resetEditedUser,
    } = this.props;

    const { config } = UsersList.columnsConfig;

    return (
      <div className="users-list">
        <QueryComposer onChange={getUsersRequest}>
          {(query, setQuery) => (
            <>
              {!!editedUser && (
                <EditUserModal
                  isOpen
                  user={editedUser}
                  isCurrentUser={editedUser.id === authUser.id}
                  onSave={(user) => this.onSaveUser(user, query)}
                  onDelete={(user) => this.onDeleteUser(user, query)}
                  onCancel={resetEditedUser}
                />
              )}
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column className="flex align-items-center">
                    <Header size="large">
                      Users List
                    </Header>
                  </Grid.Column>
                  <Grid.Column
                    className="flex justify-content-end align-items-center spaced-items"
                  >
                    <HeaderButton onClick={() => setEditedUser({ user: {} })}>
                      Create User
                    </HeaderButton>
                    <DropdownFilter
                      options={UsersList.filterConfig.options}
                      value={
                        query.is_admin != null &&
                        query.is_admin !== UsersList.filterConfig.defaultValue
                          ? query.is_admin
                          : UsersList.filterConfig.defaultValue
                      }
                      onChange={(e, data) => setQuery({
                        is_admin: data.value !== UsersList.filterConfig.defaultValue
                          ? data.value
                          : null,
                        page: null,
                      })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {isLoading ? (
                <Loader isLoading={isLoading} />
              ) : (
                <>
                  <SortableTable
                    items={list}
                    columnsConfig={UsersList.columnsConfig}
                    renderItem={this.renderItem}
                    isExternalSort
                    onSortChange={({ sortColumn, sortOrder }) => setQuery({
                      sortBy: sortColumn && config[sortColumn].dataAttribute,
                      sortOrder,
                    })}
                    sortColumn={
                      query.sortBy &&
                      Object
                        .keys(config)
                        .find((key) => config[key].dataAttribute === query.sortBy)
                    }
                    sortOrder={query.sortOrder}
                  />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column textAlign="center">
                        <Pagination
                          activePage={query.page || 1}
                          totalPages={pages || 1}
                          onPageChange={(_, data) => setQuery({
                            page: data.activePage,
                          })}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              )}
            </>
          )}
        </QueryComposer>
      </div>
    );
  }
}

const {
  getUsersRequest,
  resetState,
  setEditedUser,
  resetEditedUser,
  saveUserRequest,
  deleteUserRequest,
} = usersListSlice.actions;

export default connect(
  ({
    [usersListSlice.name]: {
      isLoading,
      list,
      pages,
      editedUser,
    },
    [authSlice.name]: {
      user,
    },
  }) => ({
    isLoading,
    list,
    pages,
    editedUser,
    authUser: user,
  }),
  {
    getUsersRequest,
    resetState,
    setEditedUser,
    resetEditedUser,
    saveUserRequest,
    deleteUserRequest,
  },
)(UsersList);
