import React from 'react';
import {
  Grid,
  Segment,
  Form,
  Button,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import authSlice from '../store/auth/slice';
import './Login.scss';

const { PUBLIC_URL } = process.env;
const PREFIX = 'login';
const FIELD_NAMES = {
  EMAIL: 'email',
  PASSWORD: 'password',
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      [FIELD_NAMES.EMAIL]: '',
      [FIELD_NAMES.PASSWORD]: '',
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    const { loginRequest } = this.props;
    loginRequest(this.state);
  };

  render() {
    const { state } = this;

    return (
      <div className="login">
        <Grid>
          <Grid.Row centered>
            <Grid.Column>
              <Segment raised>
                <img
                  alt="logo"
                  className="logo"
                  src={`${PUBLIC_URL}/images/fxc_logo.svg`}
                />
                <Form onSubmit={this.handleSubmit}>
                  <Form.Field>
                    <label htmlFor={`${PREFIX}.${FIELD_NAMES.EMAIL}`}>
                      Email
                    </label>
                    <input
                      id={`${PREFIX}.${FIELD_NAMES.EMAIL}`}
                      name={FIELD_NAMES.EMAIL}
                      value={state[FIELD_NAMES.EMAIL]}
                      onChange={this.handleChange}
                      type="text"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label htmlFor={`${PREFIX}.${FIELD_NAMES.PASSWORD}`}>
                      Password
                    </label>
                    <input
                      id={`${PREFIX}.${FIELD_NAMES.PASSWORD}`}
                      name={FIELD_NAMES.PASSWORD}
                      value={state[FIELD_NAMES.PASSWORD]}
                      onChange={this.handleChange}
                      type="password"
                    />
                  </Form.Field>
                  <Form.Field className="submit">
                    <Button color="green" type="submit">Login</Button>
                  </Form.Field>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const { loginRequest } = authSlice.actions;

export default connect(
  ({
    [authSlice.name]: {
      isLoading,
    },
  }) => ({
    isLoading,
  }),
  {
    loginRequest,
  },
)(Login);
