import React from 'react';
import {connect} from 'react-redux';
import './OrganizationsList.scss';
import {Grid, Header, Pagination,} from 'semantic-ui-react';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import organizationsListSlice from '../store/organizationsList/slice';
import Loader from '../components/Loader';
import SortableTable from '../components/SortableTable';
import QueryComposer from '../components/QueryComposer';
import DropdownFilter from '../components/DropdownFilter';
import HeaderButton from '../components/HeaderButton';
import SearchInput from '../components/SearchInput';
import lookupsSlice from '../store/lookups/slice';

const COLUMNS = {
    ID: 'ID',
    NAME: 'NAME',
    COUNTRY: 'COUNTRY',
    BRANCHES: 'BRANCHES',
    IS_PORTAL_DISPLAYED: 'IS_PORTAL_DISPLAYED',
    IS_PRICING_DISPLAYED: 'IS_PRICING_DISPLAYED',
    CREATED_AT: 'CREATED_AT',
    UPDATED_AT: 'UPDATED_AT',
};

class OrganizationsList extends React.Component {
    static columnsConfig = {
        keyAttribute: 'id',
        defaultText: '-',
        columns: [
            COLUMNS.ID,
            COLUMNS.NAME,
            COLUMNS.COUNTRY,
            COLUMNS.BRANCHES,
            COLUMNS.IS_PORTAL_DISPLAYED,
            COLUMNS.IS_PRICING_DISPLAYED,
            COLUMNS.CREATED_AT,
            COLUMNS.UPDATED_AT,
        ],
        config: {
            [COLUMNS.ID]: {
                label: 'Id',
                sortable: true,
                dataAttribute: 'id',
            },
            [COLUMNS.NAME]: {
                label: 'Name',
                sortable: true,
                dataAttribute: 'name',
                customRender: true,
            },
            [COLUMNS.COUNTRY]: {
                label: 'Country',
                dataAttribute: 'home_country_id',
                customRender: true,
            },
            [COLUMNS.BRANCHES]: {
                label: 'Branches',
                sortable: false,
                dataAttribute: 'numbranches',
            },
            [COLUMNS.IS_PORTAL_DISPLAYED]: {
                label: 'Is portal displayed',
                sortable: true,
                dataAttribute: 'is_portal_displayed',
                customRender: true,
            },
            [COLUMNS.IS_PRICING_DISPLAYED]: {
                label: 'Is pricing displayed',
                sortable: true,
                dataAttribute: 'is_pricing_displayed',
                customRender: true,
            },
            [COLUMNS.CREATED_AT]: {
                label: 'Created At',
                sortable: true,
                dataAttribute: 'created_at',
                customRender: true,
            },
            [COLUMNS.UPDATED_AT]: {
                label: 'Updated At',
                sortable: true,
                dataAttribute: 'updated_at',
                customRender: true,
            },
        },
    };

    static filterConfig = {
        options: [
            {
                text: 'All organizations',
                value: false,
            },
            {
                text: 'Eligible for collection',
                value: 'Eligible for collection',
            },
        ],
        defaultValue: false,
    };

    componentDidMount() {
        this.getLookupData();
    }

    componentWillUnmount() {
        const {resetState} = this.props;
        resetState();
    }

    getLookupData() {
        const {
            getLookupRequest,
            countries,
        } = this.props;

        const lookupTypes = [];
        if (countries.length === 0) {
            lookupTypes.push('countries');
        }
        if (lookupTypes.length > 0) {
            getLookupRequest({types: lookupTypes});
        }
    }

    isLoading() {
        return Boolean(
            this.props.isLoading ||
            this.props.countries.length === 0,
        );
    }

    renderNameColumn = (column, item) => {
        const {config} = OrganizationsList.columnsConfig;
        const idAttribute = config[COLUMNS.ID].dataAttribute;
        const currentAttribute = config[column].dataAttribute;

        return (
            <Link to={`/organizations/${item[idAttribute]}`}>
                {item[currentAttribute] || 'No Name'}
            </Link>
        );
    };

    renderCheckboxColumn = (column, item) => {
        const {dataAttribute} = OrganizationsList.columnsConfig.config[column];
        const iconClassName = cn('icon square outline checkbox-icon', {
            check: item[dataAttribute],
        });
        return (<i className={iconClassName}/>);
    };

    renderDateColumn = (column, item) => {
        const {dataAttribute} = OrganizationsList.columnsConfig.config[column];
        return new Date(item[dataAttribute]).toLocaleString();
    };

    renderCountryColumn = (column, item) => {
        const {dataAttribute} = OrganizationsList.columnsConfig.config[column];
        const countryId = item[dataAttribute];
        const country = this.props.countries.find((c) => c.id === countryId);
        return country?.name;
    };

    renderItem = (column, item) => {
        switch (column) {
            case COLUMNS.NAME:
                return this.renderNameColumn(column, item);
            case COLUMNS.IS_PORTAL_DISPLAYED:
            case COLUMNS.IS_PRICING_DISPLAYED:
                return this.renderCheckboxColumn(column, item);
            case COLUMNS.CREATED_AT:
            case COLUMNS.UPDATED_AT:
                return this.renderDateColumn(column, item);
            case COLUMNS.COUNTRY:
                return this.renderCountryColumn(column, item);
            default:
                return null;
        }
    };

    render() {
        const {
            list,
            pages,
            isLoading,
            getOrganizationsRequest,
            countries,
        } = this.props;

        const {config} = OrganizationsList.columnsConfig;
        const options = sortBy(countries.map((country) => ({
            key: country.id,
            value: '' + country.id,
            text: country.name,
        })), ['text']);

        return (
            <div className="organizations-list">
                <QueryComposer onChange={getOrganizationsRequest}>
                    {(query, setQuery) => (
                        <>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column className="flex align-items-center">
                                        <Header size="large">
                                            Organizations
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column
                                        className="justify-content-end align-items-center spaced-items">
                                        <SearchInput
                                            onChange={(search) => setQuery({
                                                ...query,
                                                search: search.length ? search : null,
                                                page: null,
                                            })}
                                            isSyncToDefault
                                            defaultValue={query.search}
                                            placeholder="Id, Name or Duns number..."
                                            dataqa="search-filter"
                                        />
                                        <DropdownFilter
                                            search
                                            clearable
                                            options={options}
                                            value={query.country || null}
                                            onChange={(e, data) => setQuery({
                                                ...query,
                                                country: data.value,
                                                page: null,
                                            })}
                                            placeholder="Country name..."
                                            data-qa="country-filter"
                                        />
                                        <DropdownFilter
                                            data-qa="table-filter"
                                            options={OrganizationsList.filterConfig.options}
                                            value={query.type || OrganizationsList.filterConfig.defaultValue}
                                            onChange={(e, data) => setQuery({
                                                type: data.value !== OrganizationsList.filterConfig.defaultValue
                                                    ? data.value
                                                    : null,
                                                page: null,
                                            })}
                                        />
                                        <HeaderButton
                                          as={Link}
                                          to="/organizations/new"
                                          data-qa="create-org"
                                          style={{float: 'right'}}
                                        >
                                            Create Organization
                                        </HeaderButton>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {isLoading ? (
                                <Loader isLoading={isLoading}/>
                            ) : (
                                <>
                                    <SortableTable
                                        items={list}
                                        columnsConfig={OrganizationsList.columnsConfig}
                                        renderItem={this.renderItem}
                                        isExternalSort
                                        onSortChange={({sortColumn, sortOrder}) => setQuery({
                                            sortBy: sortColumn && config[sortColumn].dataAttribute,
                                            sortOrder,
                                        })}
                                        sortColumn={
                                            query.sortBy &&
                                            Object
                                                .keys(config)
                                                .find((key) => config[key].dataAttribute === query.sortBy)
                                        }
                                        sortOrder={query.sortOrder}
                                    />
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column textAlign="center">
                                                <Pagination
                                                    activePage={query.page || 1}
                                                    totalPages={pages || 1}
                                                    onPageChange={(_, data) => setQuery({
                                                        page: data.activePage,
                                                    })}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </QueryComposer>
            </div>
        );
    }
}

const {
    getOrganizationsRequest,
    resetState,
} = organizationsListSlice.actions;

const {
    getDataRequest: getLookupRequest,
} = lookupsSlice.actions;

export default connect(
    ({
         [organizationsListSlice.name]: {
             isLoading,
             list,
             pages,
         },
         [lookupsSlice.name]: {
             countries,
         },
     }) => ({
        isLoading,
        list,
        pages,
        countries,
    }),
    {
        getOrganizationsRequest,
        resetState,
        getLookupRequest,
    },
)(OrganizationsList);
