import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import mappingAuditSlice from './slice';
import api from '../../api/api';

const { actions } = mappingAuditSlice;

function* getAuditSaga({ payload = {} }) {
  try {
    const auditResponse = yield call(
      api.audit.getAudit,
      payload,
    );

    yield put(actions.getAuditSuccess({
      list: auditResponse.data.list,
      pages: auditResponse.data.pages,
    }));
  } catch (e) {
    yield put(actions.getAuditFailure());
  }
}

export default function* mappingAuditSaga() {
  yield takeLatest(
    actions.getAuditRequest,
    getAuditSaga,
  );
}
