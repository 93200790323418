import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { redirect } from 'react-router-dom'
import organizationEditSlice from './slice';
import api from '../../api/api';
import get from "lodash/get";

const { actions } = organizationEditSlice;

function* getOrganizationSaga({ payload = {} }) {
  try {
    const { id } = payload;
    const response = yield call(
      api.organizations.getById,
      { id },
    );

    yield put(actions.getDataSuccess(response.data));
  } catch (e) {
    yield put(actions.getDataFailure());
  }
}

function* saveOrganizationSaga({ payload }) {
  try {
    const { data, id } = payload;

    if (data && 'google_trends_topic' in data) {
      data.google_trends_topic = data.google_trends_topic
        ? JSON.stringify(data.google_trends_topic)
        : null;
    }

    const response = yield id
      ? call(api.organizations.update, { data, id })
      : call(api.organizations.create, { data });

    yield put(actions.saveDataSuccess(response.data));
  } catch (error) {
    const message = get(error.response, 'data.message');
    yield put(actions.saveDataFailure(message));
  }
}

function returnToOrganizationList({ payload }) {
  const { id } = payload.data;
  redirect(`/organizations/${id}`)
}

export default function* organizationEditSaga() {
  yield takeLatest(
    actions.getDataRequest,
    getOrganizationSaga,
  );

  yield takeLatest(
    actions.saveDataRequest,
    saveOrganizationSaga,
  );

  yield takeLatest(
    actions.saveDataSuccess,
    returnToOrganizationList,
  );
}
