import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isModalLoading: false,
  list: [],
  pages: 1,
  editedUser: null,
};

const usersListSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    getUsersRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getUsersSuccess: (state, { payload }) => ({
      ...state,
      list: [
        ...payload.list,
      ],
      pages: payload.pages,
      isLoading: false,
    }),
    getUsersFailure: (state) => ({
      ...state,
      isLoading: false,
    }),
    setEditedUser: (state, { payload }) => ({
      ...state,
      editedUser: { ...payload.user },
    }),
    resetEditedUser: (state) => ({
      ...state,
      editedUser: null,
    }),
    saveUserRequest: (state) => ({
      ...state,
      isModalLoading: true,
    }),
    saveUserSuccess: (state) => ({
      ...state,
      isModalLoading: false,
      editedUser: null,
    }),
    saveUserFailure: (state) => ({
      ...state,
      isModalLoading: false,
    }),
    deleteUserRequest: (state) => ({
      ...state,
      isModalLoading: true,
    }),
    deleteUserSuccess: (state) => ({
      ...state,
      isModalLoading: false,
      editedUser: null,
    }),
    deleteUserFailure: (state) => ({
      ...state,
      isModalLoading: false,
    }),
    resetState: () => initialState,
  },
});

export default usersListSlice;
