import React from 'react';
import {Button, Form} from 'semantic-ui-react';
import Modal from '../components/Modal';

import {ATTRIBUTE_TYPE_LABELS, KNOWN_ATTRIBUTE_TYPES,} from '../constants/constants';

export const DATA_ATTRIBUTES = {
    TYPE: 'type',
    NAME: 'name',
    CODE: 'code',
};

const DATA_LABELS = {
    [DATA_ATTRIBUTES.TYPE]: 'Attribute Type',
    [DATA_ATTRIBUTES.NAME]: 'Entry Name',
    [DATA_ATTRIBUTES.CODE]: 'Short Code',
};

function validate(data, isCurrencyType) {
    const attrs = [
        DATA_ATTRIBUTES.TYPE,
        DATA_ATTRIBUTES.NAME,
    ];

    if (isCurrencyType) {
        attrs.push(DATA_ATTRIBUTES.CODE);
    }

    return attrs.every((attr) => (
        data[attr] != null &&
        data[attr].trim() !== ''
    ));
}

function CreateMappingModal(props) {
    const {
        isOpen,
        data,
        onCreate,
        onCancel,
        onValueChange,
    } = props;

    const isCurrencyType = data.type === KNOWN_ATTRIBUTE_TYPES.CURRENCY;

    return (
        <Modal
            title="Create Mapping Entry"
            open={isOpen}
            actions={(
                <>
                    <Button onClick={onCancel} color="red" data-qa="cancel">
                        Cancel
                    </Button>
                    <Button
                        onClick={onCreate}
                        color="blue"
                        disabled={!validate(data, isCurrencyType)}
                        data-qa="create"
                    >
                        Create
                    </Button>
                </>
            )}
        >
            <Form>
                <Form.Group widths="equal">
                    <Form.Input
                        fluid
                        readOnly
                        name={DATA_ATTRIBUTES.TYPE}
                        label={DATA_LABELS[DATA_ATTRIBUTES.TYPE]}
                        value={ATTRIBUTE_TYPE_LABELS[data[DATA_ATTRIBUTES.TYPE]] || ''}
                        data-qa={DATA_LABELS[DATA_ATTRIBUTES.TYPE]}
                    />
                    <Form.Input
                        fluid
                        name={DATA_ATTRIBUTES.NAME}
                        label={DATA_LABELS[DATA_ATTRIBUTES.NAME]}
                        value={data[DATA_ATTRIBUTES.NAME] || ''}
                        data-qa={DATA_LABELS[DATA_ATTRIBUTES.NAME]}
                        onChange={(e) => onValueChange({
                            attribute: DATA_ATTRIBUTES.NAME,
                            value: e.target.value,
                        })}
                    />
                </Form.Group>
                <Form.Group widths={2}>
                    {isCurrencyType ? (
                        <>
                            <Form.Input
                                fluid
                                name={DATA_ATTRIBUTES.CODE}
                                label={DATA_LABELS[DATA_ATTRIBUTES.CODE]}
                                value={data[DATA_ATTRIBUTES.CODE] || ''}
                                onChange={(e) => onValueChange({
                                    attribute: DATA_ATTRIBUTES.CODE,
                                    value: e.target.value,
                                })}
                            />
                        </>
                    ) : null}
                </Form.Group>
            </Form>
        </Modal>
    );
}

export default CreateMappingModal;
