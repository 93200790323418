import React, { useEffect, useState } from 'react';
import { Icon, Message } from 'semantic-ui-react';

import './Snackbar.scss';

const Snackbar = ({ variant, children }) => {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    // setTimeout(() => setVisible(false), 5000);
  }, []);

  return visible ? (
    <Message
      info={variant === 'info'}
      error={variant === 'error'}
      success={variant === 'success'}
      className="snackbar"
      data-qa="notification-msg"
    >
      <Icon
        name={
          variant === 'success'
            ? 'check circle'
            : variant === 'error'
              ? 'times circle'
              : variant === 'info'
                ? 'info circle'
                : null
        }
      />
      <b>
        {variant === 'success'
          ? 'Hurray! '
          : variant === 'error'
            ? 'Oops! '
            : variant === 'info'
              ? 'Heads Up! '
              : null}
      </b>
      <span>{children}</span>
      <Icon
        className="snackbar__close_icon"
        name="times"
        style={{ float: 'right', cursor: 'pointer' }}
        onClick={() => setVisible(false)}
      />
    </Message>
  ) : null;
};

export default Snackbar;
