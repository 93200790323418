import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Header,
  Pagination,
} from 'semantic-ui-react';
import './MappingAudit.scss';
import Loader from '../components/Loader';
import QueryComposer from '../components/QueryComposer';
import DropdownFilter from '../components/DropdownFilter';
import SortableTable from '../components/SortableTable';
import mappingAuditSlice from '../store/mappingAudit/slice';
import { ATTRIBUTE_TYPE_LABELS } from '../constants/constants';

const COLUMNS = {
  ID: 'ID',
  USER: 'USER',
  MAPPING_TYPE: 'MAPPING_TYPE',
  ORGANIZATION_NAME: 'ORGANIZATION_NAME',
  RAW_DATA: 'RAW_DATA',
  MAPPING: 'MAPPING',
  CREATED_AT: 'CREATED_AT',
};

class MappingAudit extends React.Component {
  static columnsConfig = {
    keyAttribute: 'id',
    defaultText: '-',
    columns: [
      COLUMNS.ID,
      COLUMNS.USER,
      COLUMNS.MAPPING_TYPE,
      COLUMNS.ORGANIZATION_NAME,
      COLUMNS.RAW_DATA,
      COLUMNS.MAPPING,
      COLUMNS.CREATED_AT,
    ],
    config: {
      [COLUMNS.ID]: {
        label: 'Id',
        sortable: true,
        dataAttribute: 'id',
      },
      [COLUMNS.USER]: {
        label: 'User',
        sortable: true,
        dataAttribute: 'user',
      },
      [COLUMNS.MAPPING_TYPE]: {
        label: 'Mapping Type',
        sortable: true,
        dataAttribute: 'mapping_type',
        customRender: true,
      },
      [COLUMNS.ORGANIZATION_NAME]: {
        label: 'Organization Name',
        sortable: true,
        dataAttribute: 'organization_name',
      },
      [COLUMNS.RAW_DATA]: {
        label: 'Raw Data',
        customRender: true,
      },
      [COLUMNS.MAPPING]: {
        label: 'Mapping',
        customRender: true,
      },
      [COLUMNS.CREATED_AT]: {
        label: 'Created At',
        sortable: true,
        dataAttribute: 'created_at',
        customRender: true,
      },
    },
  };

  static changesAttributesConfig = {
    [COLUMNS.RAW_DATA]: {
      beforeAttribute: 'raw_before',
      afterAttribute: 'raw_after',
    },
    [COLUMNS.MAPPING]: {
      beforeAttribute: 'mapping_before',
      afterAttribute: 'mapping_after',
    },
  };

  static filterConfig = {
    options: [
      {
        text: 'Not Selected',
        value: false,
      },
      ...Object
        .entries(ATTRIBUTE_TYPE_LABELS)
        .map(([key, label]) => ({
          text: label,
          value: key,
        })),
    ],
    defaultValue: false,
    attribute: MappingAudit
      .columnsConfig
      .config[COLUMNS.MAPPING_TYPE]
      .dataAttribute,
  };

  renderMappingTypeColumn = (column, item) => {
    const { dataAttribute } = MappingAudit.columnsConfig.config[column];
    return ATTRIBUTE_TYPE_LABELS[item[dataAttribute]];
  };

  renderChangesColumn = (column, item) => {
    const changesAttributes = MappingAudit.changesAttributesConfig[column];
    const beforeValue = item[changesAttributes.beforeAttribute];
    const afterValue = item[changesAttributes.afterAttribute];

    switch (true) {
      case beforeValue === afterValue:
        return (
          <span>{beforeValue}</span>
        );
      case beforeValue == null && afterValue != null:
        return (
          <>
            <i className="icon plus" />
            <span>{afterValue}</span>
          </>
        );
      default:
        return (
          <>
            <span>{beforeValue}</span>
            <i className="icon arrow right arrow-change" />
            <span>{afterValue}</span>
          </>
        );
    }
  };

  renderDateColumn = (column, item) => {
    const { dataAttribute } = MappingAudit.columnsConfig.config[column];
    return new Date(item[dataAttribute]).toLocaleString();
  };

  renderItem = (column, item) => {
    switch (column) {
      case COLUMNS.MAPPING_TYPE:
        return this.renderMappingTypeColumn(column, item);
      case COLUMNS.RAW_DATA:
      case COLUMNS.MAPPING:
        return this.renderChangesColumn(column, item);
      case COLUMNS.CREATED_AT:
        return this.renderDateColumn(column, item);
      default:
        return null;
    }
  };

  render() {
    const {
      isLoading,
      list,
      pages,
      getAuditRequest,
    } = this.props;

    const { config } = MappingAudit.columnsConfig;

    return (
      <div className="mapping-audit">
        <QueryComposer onChange={getAuditRequest}>
          {(query, setQuery) => (
            <>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column className="flex align-items-center">
                    <Header size="large">
                      Mapping Audit
                    </Header>
                  </Grid.Column>
                  <Grid.Column
                    className="flex justify-content-end align-items-center"
                  >
                    <DropdownFilter
                      options={MappingAudit.filterConfig.options}
                      value={query.type || MappingAudit.filterConfig.defaultValue}
                      onChange={(e, data) => setQuery({
                        type: data.value !== MappingAudit.filterConfig.defaultValue
                          ? data.value
                          : null,
                        page: null,
                      })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {isLoading ? (
                <Loader isLoading={isLoading} />
              ) : (
                <>
                  <SortableTable
                    items={list}
                    columnsConfig={MappingAudit.columnsConfig}
                    renderItem={this.renderItem}
                    isExternalSort
                    onSortChange={({ sortColumn, sortOrder }) => setQuery({
                      sortBy: sortColumn && config[sortColumn].dataAttribute,
                      sortOrder,
                    })}
                    sortColumn={
                      query.sortBy &&
                      Object
                        .keys(config)
                        .find((key) => config[key].dataAttribute === query.sortBy)
                    }
                    sortOrder={query.sortOrder}
                  />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column textAlign="center">
                        <Pagination
                          activePage={query.page || 1}
                          totalPages={pages || 1}
                          onPageChange={(_, data) => setQuery({
                            page: data.activePage,
                          })}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              )}
            </>
          )}
        </QueryComposer>
      </div>
    );
  }
}

const { getAuditRequest } = mappingAuditSlice.actions;

export default connect(
  ({
    [mappingAuditSlice.name]: {
      isLoading,
      list,
      pages,
    },
  }) => ({
    isLoading,
    list,
    pages,
  }),
  {
    getAuditRequest,
  },
)(MappingAudit);
